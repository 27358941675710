import {  OnInit, OnDestroy , ViewEncapsulation} from '@angular/core';
import { Component } from '@angular/core';
import {Router} from '@angular/router';

import * as mapsData from 'devextreme/dist/js/vectormap-data/world.js';

import { Account } from '../_models';
import {RouteConfigs, localStorageKeys } from '../_constants';
import  { AuthService} from '../auth/auth.service';
import { TemplateService } from '../apptool/service/template.service';
import  dashboardData from '../../assets/dashboard/dashboard-data.json';
import { AlertifyService } from '../_models/alertify.service';
import { MatDialog } from '@angular/material/dialog';

import { DownloadListDialog } from '../apptool/apptool.component';
import { ConfigService} from '../_common/config-service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
 	events: string[] = [];
  protected routeConfigs = RouteConfigs;
	opened: boolean = true;
	account: Account;
  regComparePermission = false;

	shouldRun = true;
  userEmail: string;
  upcomingProducts: string[] ;
  editorialUpdates: any = (dashboardData as any).editorialUpdates;
  latestPub: any;
  latestAudit: any;

  worldMap: any = mapsData.world;
  zoomFactorValue: string;
  centerValue: string;
  countries: Country[];
  mapwidth: number;
  isLoading = false; // A flag indicating that a background process is working, to determine if the UI should be frozen.
  StpDomain = environment.StpDomain;
  AuditHubDomain = environment.AudithubDomain;
  RegHubDomain = environment.RegHubDomain;

  constructor(
    private authService: AuthService,
    public tpService: TemplateService,
    private alertifyService: AlertifyService,
    private configSer: ConfigService,
    private router: Router,
    private dialog: MatDialog
  	) {
      this.centerValue = "0.000, 46.036";
      this.customizeLayers = this.customizeLayers.bind(this);
      this.zoomFactorValue = "1.00";
      this.countries = this.getCountries();
    }

  ngOnInit() {
  	this.account = AuthService.currentUser;
    this.userEmail = this.account.email;

    let permission = sessionStorage.getItem("auditHubPermissions");
    if(permission == null || permission == undefined) return;
    let feature = JSON.parse(permission).feature;

    if(feature && feature.indexOf("reg_compare") !== -1){
      this.regComparePermission = true;
    }

    // Set the map width according to the screen size.
    var el = document.getElementById('halfwidth') as HTMLElement;
    this.mapwidth = el.offsetWidth * 0.85;
    this.configSer.load().then(data => {
      this.upcomingProducts = (data as any).upcoming;
    });
    this.isLoading = true;
    this.authService.getLatestPubList()
    .subscribe(
      rep => {
        this.latestPub = rep;
        this.getTemplate();
      },
      error =>
      {
        if(error.status == 404){
          this.latestPub = [{"title": "No updates in 90 days.","acronym": "OSS"}];
        }
        this.isLoading = false;
      }
    );
  }

  getTemplate(){
    this.authService.showLoadingAnimation(true);
    this.authService.getLatestTemplate()
    .subscribe(
      rep => {
        this.latestAudit = rep;
        this.isLoading = false;
        this.authService.showLoadingAnimation(false);
      },
      error =>
      {
        if(error.status == 404){
          this.latestAudit = [{"title": "No updates in 90 days."}];
        }else{
          //alert(error.statusText);
        }
        this.isLoading = false;
        this.authService.showLoadingAnimation(false);
      }
    );
  }


  /*ngOnDestroy(): void {
  	 //localStorage.removeItem(localStorageKeys.authToken);
     this.authService.logout();
  }*/

  goPublications() {
    if(this.isLoading) return false;
    this.router.navigateByUrl("audithub/publication");
  }

  customizeTooltip(arg) {
    //console.log("hover " + arg.attribute("name"));
    if(arg.attribute("tips")) {
        return {
            text: arg.attribute("name") + ": " + arg.attribute("tips")
        };
    }
  }

  onMapClick(e){
    let target = e.target;
    let country = this.countries.find(m => m.country == target.attribute("name"));
       if(country) {
           target.selected(!target.selected());
           this.alertifyService.confirmOk(country.country + ": " + country.tips + "!");
       }
  }


  customizeLayers(elements) {
    elements.forEach((element) => {
        let country = this.countries.find(m => m.country == element.attribute("name"));
        if(country){
          let customColor = this.getColor(country.color);
          element.attribute("tips", country.tips);
          element.applySettings({
            color: customColor,
            cursor: "pointer",
            hoveredColor: "#EC8F22",
            selectedColor: "#363895"
          });
        }else{
          element.attribute("tips", "Not available");
          element.applySettings({
            color: "#99a1cd",
            hoveredColor: "#99a1cd",
            selectedColor: "#99a1cd"
          });
        }

    });
  }

  // from
  getColor(colorFlag: string): string{
    switch(colorFlag){
      case "g":
        return "#363895";
      case "r":
        return "#363895";
      case "b":
        return "#363895";
      case "y":
        return "#363895";
      default:
        return "#363895";
    }
  }

  // load contents of juresdiction from JSON file => check dashboardData for details.
  getCountries(): Country[] {
    let countries = (dashboardData as any).jurisdiction;
    return countries;
  }

  toggleSidenav() {
  	this.opened = !this.opened;
  }

   // download Xcelerators
   onDownloadTemplate(csskey: number, auditName: string){
    this.isLoading = true;
    this.tpService.showLoadingAnimation(true);
    this.tpService.downloadTempalte(csskey, this.account.id).subscribe(
      value => {
        this.tpService.showLoadingAnimation(false);

        if(value.length == 1){ // One file only, download directly.
          this.downloadFile(value[0]); // 'xcelerator-build' - the virtual directory on Web server to save the build results.
        }else{ // Multiply files, pop the file list.
          const dialogRef = this.dialog.open(DownloadListDialog, {
            width: '720px',
            data: {
              audit: auditName,
              input: value
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            console.log("checked on: " + result);
          });
        }
      },
      error =>{
        this.tpService.showLoadingAnimation(false);
        console.error(error.message);
      }
    )
  }

  downloadFile(filename: string){
    window.location.assign("xcelerator-builds/" + filename); //
  }

  regCompareDeny(){
    this.alertifyService.confirmOk("To subscribe to RegCompare, please contact us at <a href='mailto:info@stpub.com'>info@stpub.com</a>");
    //alert("RegCompare currently in development.");
  }

  onAuditClick(csskey: number, auditName:string, status: string, facilityLocation:string, start:Date, end: Date, monitor: string){
    if(status === 'upgrading') return false;
    if(this.account.userType.charAt(1) === '0') {
      this.alertifyService.confirmOk("To access your templates, please contact us at <a href='mailto:info@stpub.com'>info@stpub.com</a> to subscribe to our RegApply tool.");
      return false;
    }
    var myurl = 'audithub/apptool/edit/' + csskey ;
    this.router.navigateByUrl(myurl).then(nav => {
      let auditDate = new Date(start).toDateString() + " - " + new Date(end).toDateString();

      this.tpService.setAuditName(auditName, facilityLocation, auditDate, monitor);

    }, err =>{
      console.log(err);
    });
  }
}

export interface Country {
  country: string;
  color: string;
  tips: string;
}