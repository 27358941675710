import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import{HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';

import {ApiService} from '../../core/api.service';
import {TemplateContent, AppSect, Rulebook, RegRegister, AuditContent, Rb, TemplateRulebooks} from '../model/TemplateRulebook';
import {TemplateCategory, TemplateHead, CheckTopic, TemplateTopic, AppAnswer, TemplateAudit,TemplateShareReq} from '../model/TemplateTopics';
import {InternationalProtocol, IpRequest} from '../model/InternationalProtocol';
import {localStorageKeys} from '../../_constants';
import { Account} from '../../_models';
import { TemplateList, FilterString, FilterPubView } from '../model/TemplateList';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  currentRulebook : BehaviorSubject<string>;
  currentKey: BehaviorSubject<number>;
  currentContent: BehaviorSubject<TemplateContent[]>;
  topics: BehaviorSubject<TemplateCategory[]>;
  isLoading$: BehaviorSubject<boolean>; // Subscribe this observable to determin if the page is loading to lock it.
  hideGuidenote$: BehaviorSubject<boolean>;

  public loading = false;
  auditName: string;
  percentage: string;
  facilityLocation: string;
  auditDate: string;
  monitor: string;
  readonly MAX_TEMPLATE_COUNT_CACHED = 10;

  constructor  (private apiService: ApiService,  protected http:HttpClient) {
    this.currentRulebook = new BehaviorSubject("Fake Rulebook");
    this.currentContent = new BehaviorSubject(null);
    this.currentKey = new BehaviorSubject(0);
    this.auditName = "None";
    this.topics = new BehaviorSubject(null);
    this.percentage = "0";
    this.hideGuidenote$ = new BehaviorSubject<boolean>(true);
    this.isLoading$ = new BehaviorSubject<boolean>(false);
  }

  showLoadingAnimation(showFlag: boolean){
    this.loading = showFlag;
    this.isLoading$.next(showFlag);
  }

  getCompany(company: string){
    var targetURL = "Publication/GetCompany/" + company;
    return this.apiService.get(targetURL);
  }

  getHideGuidenote():Observable<boolean>{
    return this.hideGuidenote$;
  }

  getBuildingStatus(page: number, filter?: FilterString){
    var URL = "Apptool/GetXcelBuild/" + page ;
    if(filter !== undefined){
      var filterString = "";
      URL += "/";
      for(const k in filter){
        filterString += filter[k];
        filterString += "|";
      }
      URL +=  filterString.substring(0, filterString.length - 1);
    }
    return this.apiService.get(URL);
  }

  getPubView(page: number, start: string, filter?: FilterPubView){
    var URL = "Publication/GetPubViewStats/" + page + "/" + start;
    if(filter !== undefined){
      var filterString = "";
      URL += "/";
      for(const k in filter){
        if(filter[k]) {
          filterString += filter[k];
        }
        filterString += "|";
      }
      URL +=  filterString.substring(0, filterString.length - 1);
    }
    return this.apiService.get(URL);
  }

  // Get the email of login account.
  getLoginEmail(): string {
    const account: Account = JSON.parse(localStorage.getItem(localStorageKeys.account));
    return account.email;
  }

  setHideguidenote(flag: boolean){
    this.hideGuidenote$.next(flag);
  }

  getRulebookLocalStorage(csskey: number): TemplateContent[]{
    let cacheTemplate = localStorage.getItem('template');
    if(cacheTemplate == null) return [];

    try{
      var obj = JSON.parse(cacheTemplate);
      for(var i = 0; i < obj.length; i++){
        if(obj[i].csskey == csskey)
        return obj[i].value;
      }
      return [];
    }catch(e){
      localStorage.removeItem("template");
      return [];
    }
  }

  // Check if the template is shared by other people, and the share permission is 'View'
  isTemplateEditable(csskey: number): boolean {
    var cache = sessionStorage.getItem("template_list");
    if(cache == null) return true;

    try{
      let templateList: TemplateList[] =  JSON.parse(cache);
      let idx = templateList.findIndex(x => x.csskey == csskey);

      if(idx === -1){
        return true;
      }

      if(templateList[idx].isShared && templateList[idx].sharedBy !== 'self' && templateList[idx].permission == 'View'){
        return false;
      }else{
        return true;
      }
    }catch(e){
      this.removeCache();
      return true;
    }
  }

  // Call backend to remove the 'checkUpdate' flag.
  removeCheckUpdateFlag(csskey: number, appkey: number){
    var targetURL = "Apptool/RemoveCheckUpdateFlag";
    return this.apiService.post(targetURL, {"Csskey": csskey, "Appkey":appkey, "Flag": false});
  }


  /**
   * remove flag in the local storage.
   * @param csskey: template key
   * @param appkey: applicability key number
   * @param flag:  1- 'needsReview'; 2- 'checkUpdate'
   */
  revokeReviewFlag(csskey: number, appkey: number, flag: number){
    var tp = this.getRulebookLocalStorage(csskey);

    if(tp !== null){
      var rb = tp.flatMap(t => t.topics).flatMap(r => r.rulebooks).find(c => c.appKey == appkey);
      if(rb === undefined) return;
      if(flag == 1){
        rb.needsReview = false;
        this.cacheRulebookLocalStorage(csskey, tp);
      }else if(flag == 2){
        rb.checkUpdate = false;
        this.cacheRulebookLocalStorage(csskey, tp);
      }
    }
  }

  // Remove the template from the local storage.
  removeTemplateLocalStorage(csskey: number){
    let cacheTemplate = localStorage.getItem('template');
    if(cacheTemplate == null) return;

    try{
      var obj = JSON.parse(cacheTemplate);
      let idx = obj.findIndex(x => x.csskey == csskey);

      if(idx !== -1){
        obj.splice(idx, 1);
        localStorage.setItem("template", JSON.stringify(obj));
      }
    }catch(e){
        localStorage.removeItem("template");
        return;
    }
  }

  // Save template list into session storage.
  cacheTemplateList(data: TemplateList[]){
    // var item = "{\"mine\":" + mine +", \"progress\":" + progress +", \"value\":"+ JSON.stringify(data) + "}";
     sessionStorage.setItem("template_list", JSON.stringify(data));
  }

  // update cssdate in the session storage
  updateTemplateDateStorage(csskey: number){
    var cache = sessionStorage.getItem("template_list");
    if(cache == null) return;

    try{
      let templateList: TemplateList[] =  JSON.parse(cache);
      let idx = templateList.findIndex(x => x.csskey == csskey);

      if(idx !== -1){
        templateList[idx].cssDate = new Date();
        this.cacheTemplateList(templateList);
      }
    }catch(e){
      this.removeCache();
    }
  }


  // Update session storage when isShared changed for the template.
  updateTemplateList(csskey: number, isShared: boolean, permission: string){
    var cache = sessionStorage.getItem("template_list");
    if(cache == null) return;

    try{
      let templateList: TemplateList[] =  JSON.parse(cache);
      let idx = templateList.findIndex(x => x.csskey == csskey);

      if(idx !== -1){
        templateList[idx].isShared = isShared;
        templateList[idx].permission = permission;
        this.cacheTemplateList(templateList);
      }
    }catch(e){
      this.removeCache();
    }
  }

   // Load template list from session storage.
   loadFromCache() : TemplateList[]{
     var cache = sessionStorage.getItem("template_list");
     if(cache === null) return [];
     try{
       return JSON.parse(cache);
      }catch(e){
        this.removeCache();
        return [];
    }
   }

   // delete template list from session storage.
   removeCache(){
     sessionStorage.removeItem("template_list");
   }

   /**
    * To remove a template from the template list on session storage.
    * @param csskey template key
    */
   removeItemFromCache(csskey: number){
     var cache = sessionStorage.getItem("template_list");
     if(cache === null) return false;

     try{
      let templateList: TemplateList[] =  JSON.parse(cache);
      let idx = templateList.findIndex(x => x.csskey == csskey);

      if(idx !== -1){
        templateList.splice(idx, 1);
        this.cacheTemplateList(templateList);
      }
     }catch(e){
      this.removeCache();
     }
   }

   // Update session cache for template list after upgrade template
  UpdateSessionCache(oldcsskey: number, csskey: number){
    var cache = sessionStorage.getItem("template_list");
     if(cache === null) return false;

     try{
      let templateList: TemplateList[] =  JSON.parse(cache);
      let idx = templateList.findIndex(x => x.csskey == oldcsskey);

      if(idx !== -1){
        templateList[idx].status = "upgrading";
        templateList[idx].isUpdateAvailable = false;
        this.cacheTemplateList(templateList);
      }
     }catch(e){
      this.removeCache();
     }
  }


   /**
    * To add a template into the template list on session storage.
    * @param item
    */
   addItemIntoCache(item: TemplateList){
     if(!item) return;
     var cache = sessionStorage.getItem("template_list");

     if (cache == null){
      var data: TemplateList[] = [item];
      this.cacheTemplateList(data);
     } else{
        try{
          var data: TemplateList[] = JSON.parse(cache);
          data.splice(0, 0, item);
          this.cacheTemplateList(data);
        }catch(e){
          this.removeCache();
        }
     }
   }

  /**
   * Remove the template from local storage
   * Reason: 1) the template changed(add topics); 2)there is no space
   * @param csskey the key number of the template
   */
  removeTemplateFromStorage(csskey: number){
    var cacheTemplate = localStorage.getItem('template');
    if(cacheTemplate === null) return;

    let obj: AuditContent[] = JSON.parse(cacheTemplate);
    obj.forEach((item, index) => {
      if(item.csskey == csskey){
        obj.splice(index, 1);
      }
    });
    if(obj.length === 0) {
      localStorage.removeItem("template");
    }else{
      localStorage.setItem("template", JSON.stringify(obj));
    }
  }

  /**
   * Remove a topic of the template from local storage.
   * Scenario: a topic is removed from the tempalte, then we need sync the local storage to reflect the changes.
   * @param csskey the key number of the template
   * @param topicKey the topic need to be removed.
   */
  removeTopicFromStorage(csskey: number, topicKey: number){
    var cacheTemplate = localStorage.getItem('template');
    if(cacheTemplate === null) return;

    try{
      let obj: AuditContent[] = JSON.parse(cacheTemplate);

      for(var audit of obj){
        if(audit.csskey != csskey) continue;
        var valueIndex = 0;
        for(var pub of audit.value){
          for(const{index, topic} of pub.topics.map((topic, index) =>({index,topic}))){
            if(topic.topicKey == topicKey){
              if(pub.topics.length == 1){ // delete all acronym since there is no topic left.
                audit.value.splice(valueIndex, 1);
              }else{ // delete the topic.
                pub.topics.splice(index, 1);
              }
              localStorage.setItem("template", JSON.stringify(obj));
              return false;
            }
          }
          valueIndex++;
        }
      }
    }catch(e){
      localStorage.removeItem("template");
    }
  }

  /**
   * To remove a custom topic from local storage.
   * Scenario: a custom topic is removed from the template by the user.
   * @param csskey the key number of the template
   * @param rbKey the topic key of the custom topic(CustTopicKey in database). It was written as rbKey to keep it compatiable to regular topic in local storage.
   */
  removeCustomTopicFromStorage(csskey: number, rbKey: number){
    var cacheTemplate = localStorage.getItem('template');
    if(cacheTemplate === null) return;

    try{
      let obj: AuditContent[] = JSON.parse(cacheTemplate);

      for(var audit of obj){
        if(audit.csskey === csskey){
          for(var pub of audit.value){
            if(pub.acronym === "Custom"){
              var tpIndex = 0;
              for(var tp of pub.topics){
                for(const {index,rb} of tp.rulebooks.map((rb, index) =>({index, rb}))){
                  if(rb.rbKey === rbKey){
                    if(tp.rulebooks.length === 1){ // delete the whole topic because no rulebook will leave.
                      pub.topics.splice(tpIndex, 1);
                    }else{
                      tp.rulebooks.splice(index, 1);// delete the rulebook
                    }
                    localStorage.setItem("template", JSON.stringify(obj));
                    return false;
                  }
                }
                tpIndex++;
              }
            }
          }
        }
      }
    }catch(e){
      localStorage.removeItem("template");
    }

  }

  // Save rulebooks to local storage for cache
  cacheRulebookLocalStorage(csskey: number, data: TemplateContent[]){
    let cacheTemplate = localStorage.getItem('template');
    var item = "{\"csskey\":" + csskey +", \"value\":"+ JSON.stringify(data) + "}";

    if(cacheTemplate == null || cacheTemplate.length == 0){
      localStorage.setItem("template", "[" + item + "]");
    }else{
      if(cacheTemplate.indexOf(csskey.toString()) == -1){
        let tmp = cacheTemplate.slice(0, -1); // Remove the last char ']'
        localStorage.setItem("template", tmp + "," + item + "]");
        this.truncateRulbookLocalStorage();
      }else{
        var obj = JSON.parse(cacheTemplate);
        for (var i = 0; i < obj.length; i++){
          if(obj[i].csskey == csskey){
            obj[i].value = data;
          }
        }
        localStorage.setItem("template", JSON.stringify(obj));
      }
    }
  }

  // To keep the number of templates in the local storage not greater than 15.
  truncateRulbookLocalStorage(){
    var cacheTemplate = localStorage.getItem('template');
    if(cacheTemplate === null) return;

    let data: AuditContent[] = JSON.parse(cacheTemplate);
    if(data.length > this.MAX_TEMPLATE_COUNT_CACHED) { // Only keep the first 15 templates in the storage.
      let latest = data.splice(data.length - this.MAX_TEMPLATE_COUNT_CACHED, this.MAX_TEMPLATE_COUNT_CACHED);
      localStorage.setItem("template", JSON.stringify(latest));
    }
  }



  /**
   *  Save the rulebook in the local storage for selected custom topic.
   *  for custom topic, it only has two level: topic - rulebook
   *  We treat topic as acronym, rulebook as topic on UI, but save it in local storage as topic and rulebook.
   * @param csskey: template key
   * @param topic: topic name -- it is the acronym on UI
   * @param rbName: rulebook name -- it is the topic on UI
   * @param rbKey: the key for rulebook name -- in local storage, the field name is rbKey, in database, it is the CustTopicKey.
   * @returns none
   */
  addCustomTopicLocalStorage(csskey: number, topic: string, rbName: string, rbKey: number){
    let cacheTemplate = localStorage.getItem('template');

    // Do not create the template item in the local storage if there is no such template yet,
    // because it means that the template has not built applicability yet. Unlike custom topic, we don't have the rulebooks for the regular topic,
    // so if the customer has checked regular topics before custom topic, then the template in the local storage only has custom rulebooks,
    // which is not reflecting the whole rulebooks accurately.
    if(cacheTemplate == null) return false;
    if(cacheTemplate.indexOf(csskey.toString()) == -1) return false;

    var obj = JSON.parse(cacheTemplate);

    for (var i = 0; i < obj.length; i++){
      if(obj[i].csskey == csskey){
        var vl: TemplateContent[] = obj[i].value;
        var customTopic = vl.find(x => x.acronym === "Custom");

        if(customTopic === undefined){ // No custom topic yet, then create the custom topic
          const ct = this.createCustomTopc(topic, rbKey, rbName);
          vl.push(ct);

        }else { // There is custom topic, add the new rulebook.
          // Check if there is the topic
          var tp = customTopic.topics.find(x => x.topic === topic);

          if(tp === undefined){ // No this topic yet, create this topic object and push it into the array.
            const tpInstance = this.createNewTopic(topic, rbKey, rbName);
            customTopic.topics.push(tpInstance);
          }else{
            const rb = this.createNewRb(rbKey, rbName);
            tp.rulebooks.push(rb);
          }
        }
      }
    }
    localStorage.setItem("template", JSON.stringify(obj));
  }

  createCustomTopc(topic: string, rbKey: number, rbName: string): TemplateContent {
    const tp = this.createNewTopic(topic, rbKey, rbName);

    var ct: TemplateContent = {
      acronym: "Custom",
      releaseNum: 0,
      percentage: "",
      topics: [tp]
    };
    return ct;
  }

  createNewTopic(topic: string, rbKey: number, rbName: string): TemplateRulebooks{
    const rb = this.createNewRb(rbKey, rbName);
    var tp:  TemplateRulebooks = {
      topic: topic,
      topicKey: 0,
      rulebooks: [rb]
    };

    return tp;
  }

  createNewRb(rbKey: number, rbname: string): Rb{
    return  {
      appKey: 0,
      rbKey: rbKey,
      rbName: rbname,
      checkNa: false,
      dateModified: null,
      done: false,
      needsReview: false,
      abridged: false,
      percentage: '',
      status: '',
      appAnswer: '',
      appSect: null,
      checkUpdate: false
    };
  }


  // Call backend to get citation link.
  getCitation(ciation:string){
    var URL = "Apptool/Citation/" + ciation;
    return this.apiService.get(URL);
  }

  // Call backend to get paragraph content.
  getPara(paraKey: number){
    var targetURL = "Publication/Paragraph/" + paraKey;
    return this.apiService.get(targetURL);
  }

  getTpTopics(){
    return this.topics;
  }

  setTpTopics(tc: TemplateCategory[]){
    this.topics.next(tc);
  }

  getStateList(csskey: number){
    var URL = "Apptool/GetStateList/" + csskey;
    return this.apiService.get(URL);
  }


  setTpTopic(tp: TemplateTopic){
    var tc = this.topics.value;

    if(tc == null)
      return false;

    tc.forEach(c => {
      c.acronyms.forEach(a => {
        a.topics.forEach(t => {
          if(t.topicKey == tp.topicKey){
            t.selected = tp.selected;

            if(tp.selected)
              a.selected++;
            else
              a.selected--;
          }
        })
      })
    });

    this.topics.next(tc);
  }


  // Get state saved from the last operation
  getFavoriteState(csskey: string): string {
    try{
      let state = localStorage.getItem("favorite_state");
      if(state == null) return null;

      var obj = JSON.parse(state);
      for(var i = 0; i < obj.length; i++){
        if(obj[i].csskey == csskey)
          return obj[i].state;
      }
      return null;
    }catch(excemption){
      console.error(excemption);
      return null;
    }
  }

  // Save the state for the template in the local storage.
  cacheFavoriteState(csskey: string, state: string){
    if(state == '1' || state == '0') return false;
    let states = localStorage.getItem("favorite_state");
    var item = "{\"csskey\":" + csskey +", \"state\":\""+ state + "\"}";

    if(states == null){
      localStorage.setItem("favorite_state", "[" + item + "]");
    }else{
      if(states.indexOf(csskey.toString()) == -1){
        let tmp = states.slice(0, -1); // Remove the last char ']'
        localStorage.setItem("favorite_state", tmp + "," + item + "]");
      }else{
        var obj = JSON.parse(states);
        for (var i = 0; i < obj.length; i++){
          if(obj[i].csskey == csskey){
            obj[i].state = state;
          }
        }
        localStorage.setItem("favorite_state", JSON.stringify(obj));
      }
    }
  }

  getAuditName(){
    return this.auditName;
  }

  getFacilityLocation(){
    return this.facilityLocation;
  }

  getAuditDate(){
    return this.auditDate;
  }

  // Check if the template has been pushed to Intelex.
  getIntelexFlag(): boolean{
    if(this.monitor){
      return this.monitor.toLowerCase().includes("intelex");
    }
    return false;
  }

  setAuditName(audit: string|null, facilityLocation:string, auditDate: string, monitor: string){
    this.auditName = audit || '';
    this.facilityLocation = facilityLocation;
    this.auditDate = auditDate;
    this.monitor = monitor;
  }

  setAuditPercentage(percentage: string){
    if(percentage == null)
      percentage = "0";
    this.percentage = percentage;
  }

  getAuditPercentage(){
    return this.percentage;
  }


  // find the topic where the appkey belongs to.
  getTopic(appkey: number) {
    if(this.currentContent == null){
      return "No topic";
    }

    var result = "No matched topic";

    this.currentContent.value.forEach(element => {
      element.topics.forEach(tp => {
        tp.rulebooks.forEach(rb => {
          if(rb.appKey == appkey){
            result = tp.topic;
          }
        })
      })
    });
    return result;
  }

  // button 'nexe rulebook' is clicked, then find the next appkey which is not 'check N/A'.
  getNextAppkey(){
    var currentKey = this.getKey().value;
    var contents = this.currentContent.value;
    var len = contents.length;
    var found = false;

    for(var i = 0; i < len; i++){
      var tps = contents[i].topics;

      for(var j = 0; j < tps.length; j++){
        var rbs = tps[j].rulebooks;

        for(var k = 0; k < rbs.length; k++){
          if(found == true){
            if(rbs[k].checkNa == false && rbs[k].abridged == false){// skip checkNa== true; skip abridged == true
              return {
                first: rbs[k].appKey,
                second: rbs[k].rbName,
              }
            }
          }
          if(rbs[k].appKey == currentKey){
            found = true;
          }
        }
      }
    }
    return null;
  }

   // button 'nexe rulebook' is clicked, then find the next appkey which is not 'check N/A'.
   getNextRbkey(){
    var currentKey = this.getKey().value;
    var contents = this.currentContent.value;
    var len = contents.length;
    var found = false;

    for(var i = 0; i < len; i++){
      var tps = contents[i].topics;

      for(var j = 0; j < tps.length; j++){
        var rbs = tps[j].rulebooks;

        for(var k = 0; k < rbs.length; k++){
          if(found == true){
            if(rbs[k].checkNa == false){
              return {
                first: rbs[k].rbKey,
                second: rbs[k].rbName,
              }
            }
          }
          if(rbs[k].rbKey == currentKey){
            found = true;
          }
        }
      }
    }
    return null;
  }

  getKey(){
    return this.currentKey;
  }

  setKey(appkey: number){
    this.currentKey.next(appkey);
  }

  getCurrent(){
    return this.currentContent;
  }

  getPreauditAndIntro(topickey: string){
    var URL = "Apptool/GetPreauditAndIntro/" + topickey;
    return this.apiService.get(URL);
  }


  setCurrent(contents: TemplateContent[]){
    this.currentContent.next(contents);
  }

  getRbName(){
    return this.currentRulebook;
  }

  setRbName(audit: string){
    this.currentRulebook.next(audit);
  }

  // To calculate CheckNa flag for the rulebook by the answer
  // if the answer to rulebook applicability question is "N" or the answer to rulebook exemption question is 'y', it's CheckNa;
  calCheckNa(answer:string){
    if(answer){
      if(answer.charAt(0) == 'N'){
        return true;
      }
      if(answer.length >= 2 && answer.charAt(1) == 'y'){
        return true;
      }
    }
    return false;
  }

  // call backend to build Reg Regiser.
  buildRegRegiser(csskey: number){

  }

  // To calculate the question numbers in a rulebook by the answer
  calQuestionSum(answer:string) {
    if(answer == null) return {sum: 0, answered: 0};
    var sum = 0;
    var answered = 0;
    var previousC = '0';

    for(const c of answer){
      if( 'A' < c && c < 'Z'){ // answer to applicability will be either one of 'Y', 'N', 'I'
        sum++;
        if(c == 'Y' || c == 'N'){
          answered++;
        }
      } else { // answer to exemption will be one of 'y', 'n', 'i'
        if(previousC != 'N'){
          sum++;
          if(c == 'y' || c == 'n'){
            answered++;
          }
        }
      }
      previousC = c;
    }
    return {sum, answered };
  }

  /* Calculate complete percentage of a rulebook applicability
      @input: 'YYYNIYi'
      @output: '82.3%' = the number of answers / the number of all questions (app question + exemption question)

      rule 1: If the answer to applicability question is no, then the exemption is ignored.
  */
  calRulebookPercentage(answer: string){
    if(answer == null)
      return "0%";
    let {sum, answered} = this.calQuestionSum(answer);
    return this.calPercentage(answered, sum);
  }

  calPercentage(answered: number, sum: number){
    if(sum == 0){
      return "100%";
    }else{
      if(answered == sum) return "100%"; // To avoid outputting '100.0%' like next line does.
      return Math.floor((answered / sum) * 100).toFixed(1) + "%";
    }
  }

  // Call STP API to get acronym list to create template.
  getAcronyms(csskey?: number): Observable<TemplateAudit>{
    var URL = "Apptool/GettopicsForAudit/";

    if(csskey != undefined){
      URL += csskey;
    }

    return this.apiService.get(URL);
  }

  // Call STP API to get topic list for acronym.
  getTopics(acronym: string, releasenum: number, csskey: number, selectedTopic: number): Observable<TemplateTopic[]>{

    if(selectedTopic === 0)
      return this.apiService.get("Apptool/GetTopics/"  + releasenum + "/0/" + encodeURI(acronym));
    else
      return this.apiService.get("Apptool/GetTopics/"  + releasenum + "/" + csskey + "/" + encodeURI(acronym));
  }

  // Call STP API to build Xcelerator
  buildXcelerator(csskey: number, buildType: string, answer: string, stateCode?: string[], ){
    var url = "Apptool/buildXcelerator";
    if(!stateCode || stateCode.length == 0)
      stateCode = [];
    return this.apiService.post(url, {"csskey": +csskey, "buildType": buildType.toString(), "state": stateCode, "answerCode": answer??""});
  }

  // Get Intelex preanswer list
  getIntelexAnswer(){
    var url = "Apptool/Intelex/preanswer";
    return this.apiService.get(url);
  }

  getAuditPrint(csskey: number): Observable<TemplateContent[]> {
    var URL = "Apptool/GetAuditPrint/";

    if(csskey != undefined){
      URL += csskey;
    }
    return this.apiService.get(URL);
  }

  // call backend to update the cssdate of the template.
  touchTemplate(csskey: string){
    let URL = "Apptool/TouchTemplate/" + csskey;
    return this.apiService.post(URL);
  }

  rebuildTemplate(buildId: number){
    var URL = "Apptool/Rebuild?buildId=" + buildId;
    return this.apiService.post(URL);
  }

  getContent(csskey: number | string): Observable<TemplateContent[]> {
    //console.log("getContent: " + csskey);
    let URL = "Apptool/GetContent/" + csskey;
    return this.apiService.get(URL);
  }

  createTemplate(th: TemplateHead){
    let URL = "Apptool/Create";
    return this.apiService.post(URL, th);
  }

  updateTopic(data: CheckTopic){
    let URL = "Apptool/UpdateTopic";
    return this.apiService.put(URL, data);
  }

  updateSharePermission(data: TemplateShareReq){
    let URL = "Apptool/UpdateShare";
    return this.apiService.put(URL, data);
  }

  checkNa(csskey:number, appkey:number, flag: boolean){
    let URL = "Apptool/SetCheckNa";
    let data = {
      csskey: csskey,
      appkey: appkey,
      flag: flag
    };
    return this.apiService.put(URL, data);
  }

  deleteTemplate(csskey: number){
    let url = "Apptool/" + csskey;
    return this.apiService.delete(url);
  }

  archiveTemplate(csskey: number){
    let url = "Apptool/Archive/" + csskey;
    return this.apiService.put(url);
  }

  upgradeTemplate(csskey: number){
    let url = "Apptool/UpgradeTemplate/" + csskey;
    return this.apiService.get(url);
  }

  // call backend to get the xcelerator list for the template.
  downloadTempalte(csskey: number, userid: number){
    let url = "Publication/GetXcelerator/" + csskey + "/" + userid;
    return this.apiService.get(url);
  }


  downloadFile(filePath: string){
    let headers = new HttpHeaders(); //.set('authorization','Bearer '+token);
    //headers = headers.set('Accept', 'application/octet-stream');
    return this.http.get(filePath, {headers: headers, responseType: 'blob'});
  }

  unarchiveTemplate(csskey: number){
    let url = "Apptool/Unarchive/" + csskey;
    return this.apiService.put(url);
  }

  getApplicability(csskey: number | string, appkey: number | string): Observable<AppSect[]> {
    let URL = "Apptool/GetApplicableQuestions/" + csskey + "/" + appkey;
  	return this.apiService.get(URL);
  }

  getApplicableRulebookWithState(csskey: number, rbkey: number, state?:string, custom?:boolean):Observable<Rulebook>{
    var url: string;
    if(custom)
      url = "Apptool/GetCustomRulebook/"  + rbkey;
    else if(state === 'undefined' || state === null || state === "")
      url = "Apptool/GetApplicableRulebook/" + csskey + "/" + rbkey;
    else
      url = "Apptool/GetApplicableRulebook/" + csskey + "/" + rbkey + "/" + state;

  	return this.apiService.get(url);
  }

  getRegRegister(csskey: number, state?: string): Observable<RegRegister[]>{
    var url: string;
    if(state === 'undefined' || state === null)
      url = "Apptool/GetRegRegister/" + csskey;
    else
      url = "Apptool/GetRegRegister/" + csskey + "/" + state;
    return this.apiService.get(url);
  }

  copyTemplate(csskey: number){
    let url = "Apptool/Copy/" + csskey;
    return this.apiService.put(url);
  }

  getTemplateRefkey(csskey: string){
    let url = "Apptool/GetTemplateRefkey/" + csskey;
    return this.apiService.get(url);
  }

  // Call backend to retrieve the data to display the changes of a rulebook related to the appkey.
  reviewUpdate(csskey: number, appkey: number){
    let url = "Apptool/GetReview/" + csskey + "/" + appkey;
    return this.apiService.get(url);
  }

  updateAppAnswer(csskey:  string, appkey:number , answer: string){
    let URL = "Apptool/AnswerApp";
    let data: AppAnswer = {
      csskey: +csskey,
      appkey: appkey,
      answer: answer
    };
    return this.apiService.put(URL, data);
  }

  decodeHtml(html: string){
    if(html == null) return null;
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  decodeEntity(text: string){
      var entities = [
          ['amp', '&'],
          ['apos', '\''],
          ['#x27', '\''],
          ['#x2F', '/'],
          ['#39', '\''],
          ['#47', '/'],
          ['lt', '<'],
          ['gt', '>'],
          ['nbsp', ' '],
          ['quot', '"']
      ];

      for (var i = 0, max = entities.length; i < max; ++i)
          text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);

      return text;
  }

  // call backend to submit IP Request.
  submitIpReqeust(data: IpRequest){
    let URL = "Iprequest/Create";
    return this.apiService.post(URL, data);
  }

  // Get IP list
  getInternationalProtocol(): Observable<InternationalProtocol[]>{
    let URL = "Iprequest/GetIpRequest";
    return this.apiService.get(URL);
  }

  // Get My IP list
  getMyIP(userId: number): Observable<InternationalProtocol[]>{
    let URL = "Iprequest/GetMyIp/" + userId;
    return this.apiService.get(URL);
  }
}