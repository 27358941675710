import { Component, OnInit, Output, Input,EventEmitter } from '@angular/core';
import { AlertifyService } from '../_models/alertify.service';
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {StateListDialog} from '../apptool/edit-template/edit-template.component';
import { TopicTreeDialog } from './topic-tree.component';
import { ComparisonDef,  } from '../regcompare/statediff-model';

@Component({
  selector: 'app-rc-search-filter',
  templateUrl: './rc-search-filter.component.html',
  styleUrls: ['./rc-search-filter.component.scss']
})
export class RcSearchFilterComponent implements OnInit {
  isCAAccess = true;
  loading = false;
  states: {code: string, name:string}[] = [];
  selectedStates: string[] = [];
  selectedTopics: string[] = [];
  searchWord:string;
  filteredTopic: string;
  selectedComparisonId = 0;
  displayDef: ComparisonDef[] = [];


  @Output() applyCompareEvent = new EventEmitter<{st: string[], tk: string[]}>();
  @Output() deleteComparisonDefEvent = new EventEmitter<{cKey: string}>();
  @Output() runComparisonDefEvent = new EventEmitter<{id: number}>();
  @Input() comparisonDef: ComparisonDef[];

  constructor(public dialog:MatDialog,
    private alertifyService: AlertifyService) { }

  ngOnInit(): void {

  }

  ClearAll(){
    this.ClearJurisdiction();
    this.ClearTopic();
    /*
    const ele = document.getElementsByClassName('radiobutton') as HTMLCollectionOf<HTMLInputElement>;

    for(var i = 0; i < ele.length; i++){ // Uncheck the radio button of Comparison Definition.
        ele[i].classList.remove('mat-radio-checked');
    }*/
    this.selectedComparisonId = 0; // No Comparison is selected.
    this.applyCompareEvent.emit({st: this.selectedStates, tk: this.selectedTopics});
  }

  ClearJurisdiction() {
    this.selectedStates = [];
  }

  ClearTopic() {
    this.selectedTopics = [];
  }

  filterTopic(topic: string){
    if(this.filteredTopic === topic ){
      this.filteredTopic = ""; // Cancel the filtered topic if click again.
    }else{
      this.filteredTopic = topic;
    }
    const tmpArray = [topic];
    this.applyCompareEvent.emit({st: [], tk: tmpArray});
  }

  async showTopicPopup(){
    const result = await this.openTopicTreeDialog();

      if(result !== null) {
        this.selectedTopics = result.sort();
        return;
      }
  }


  async showJurPopup(){
    var states = this.getAuthorizedState();

    if(states !== undefined){
      const result = await this.openStateListDialog(this.selectedStates.join(","), "regcompare", states);

      if(result !== null) {
        this.selectedStates = result.sort();
        return;
      }
    }
  }

  getAuthorizedState(): string[]{
    var authorized_state: string[] = [];
    let permission = sessionStorage.getItem("auditHubPermissions");
    if(permission == null || permission == undefined) return authorized_state;

    let eafSd = JSON.parse(permission).eaf;
    let ofSd = JSON.parse(permission).of;

    if(eafSd && eafSd.trim().length > 0){
      authorized_state = authorized_state.concat(eafSd.toUpperCase().split(","));
    }
    if(ofSd && ofSd.trim().length > 0){
      authorized_state = authorized_state.concat(ofSd.toUpperCase().split(","));
    }
    if(authorized_state.length == 0) return authorized_state;
    return authorized_state.filter((v,i,a) => a.indexOf(v) === i).sort();
  }

  async openTopicTreeDialog(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {selected: this.selectedTopics};
    dialogConfig.width = '1200px';
    const dialogRef = this.dialog.open(TopicTreeDialog, dialogConfig);

    return dialogRef.afterClosed()
    .toPromise()
    .then(result => {
      return Promise.resolve(result);
    });

  }

  async openStateListDialog(state: string, buildType: string, authorized_state:string[]): Promise<string[]> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      selected: state || "",
      buildType: buildType,
      states: authorized_state
    };
    dialogConfig.width = '920px';
    const dialogRef = this.dialog.open(StateListDialog, dialogConfig);

    return dialogRef.afterClosed()
    .toPromise()
    .then(result => {
      return Promise.resolve(result);
    });
  }

  openComparisonDef(): void {
    this.displayDef = this.comparisonDef;
  }



  clearAllSavedSearch(){}

  //Click on the radio button of saved comparison definition.
  onComparisonSelected(cId: number){
    console.log("radio selected: " + cId);
    let selected = this.comparisonDef.find(x => x.id == cId);
    if(selected === undefined) return;
    this.selectedStates = selected?.states.split(',');
    this.selectedTopics = selected?.topics.split(',');
    this.selectedComparisonId = cId;
  }

  isComparisonSelected(current: number){
    return current == this.selectedComparisonId;
  }

  runComparisonDef(){
    if(this.selectedComparisonId == 0) return;
    this.runComparisonDefEvent.emit({id: this.selectedComparisonId});
  }

  onSearchComparison(searchWord: string){
    this.displayDef = [];
    for(var i = 0; i < this.comparisonDef.length; i++){
      let index = this.comparisonDef[i].comparisonName.toUpperCase().indexOf(searchWord.toUpperCase());
      if(index != -1){
        this.displayDef.push(this.comparisonDef[i]);
        continue;
      }
    }
  }


  onDeleteComparison(comparisonKey: string, cName: string){
    this.alertifyService.confirmOkCancel("RegCompare", "Are you sure you want to delete the item: " + cName + "?",
    () =>{
      const index = this.displayDef.findIndex(x => x.id.toString() == comparisonKey);
      if(index > -1){
        this.displayDef.splice(index, 1);
      }
      this.deleteComparisonDefEvent.emit({cKey:comparisonKey});
    },
    () =>{
      console.log("cancel delete.");
    });
  }

  onRegCompare(){
    this.applyCompareEvent.emit({st: this.selectedStates, tk: this.selectedTopics});
  }

}