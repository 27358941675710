import { Component, ElementRef, OnInit, Injectable, Inject } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import { AlertifyService } from '../_models/alertify.service';
import { PublicationService } from '../publication/publication.service';
import {RulebookCompare} from './subtopics';
import {RbStateDiffs,RbkeyStateDiffs,StateDiffs,ParaStateDiffs, ComparisonDef} from './statediff-model';
import  XLSX from 'sheetjs-style';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA,MatDialogConfig} from '@angular/material/dialog';

/**
 * Node for to-do item
 */
 export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
  dataChange2 = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  get data2(): TodoItemNode[] {
    return this.dataChange2.value;
  }

  constructor() {
    this.initialize();
  }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    var rulebookCompare = new RulebookCompare();
    let source = rulebookCompare.TREE_DATA;
    const data = this.buildFileTree(source, 0);
    const data2 = this.buildFileTree(rulebookCompare.TREE_DATA2, 0);

    // Notify the change.
    this.dataChange.next(data);
    this.dataChange2.next(data2);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: {[key: string]: any}, level: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.item = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: TodoItemNode, name: string) {
    if (parent.children) {
      parent.children.push({item: name} as TodoItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }
}


@Component({
  selector: 'app-regcompare',
  templateUrl: './regcompare.component.html',
  styleUrls: ['./regcompare.component.css'],
  providers: [ChecklistDatabase]
})
export class RegcompareComponent  {
  states: {code: string, name:string}[] = [];
  loading = false;
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;
  /** The new item's name */
  newItemName = '';
  compareData: RbStateDiffs[] = [];
  displayData: RbStateDiffs[] = [];
  comparisonDef: ComparisonDef[] = [];

  treeControl: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  dataSource2: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  jurisdictionExpand = true;
  selectedStates = [];
  selectedTopics = [];
  comparison: string = "";
  comparisonId = 0;

  constructor(
    public dataService: PublicationService,
    private _database: ChecklistDatabase,
    public dialog: MatDialog,
    private alertifyService: AlertifyService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this._database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
    /*
    this.dataSource2 = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this._database.dataChange2.subscribe(data => {
      this.dataSource2.data = data;
    });*/
  }

  getLevel = (node: TodoItemFlatNode) => node.level;
  isExpandable = (node: TodoItemFlatNode) => node.expandable;
  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;
  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  ngOnInit() {
    this.treeControl.expand(this.treeControl.dataNodes[0]);
    this.onListRegCompare();
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  printAllSelectedTopic(){
    var selected = this.checklistSelection.selected;
    this.selectedTopics = [];
    selected.forEach(x => {
      if(!x.expandable){
        //console.log("selected " + x.item);
        this.selectedTopics.push(x.item);
      }
    });
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    this.printAllSelectedTopic();
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.printAllSelectedTopic();
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }


  /**
   *
   */
  onRegCompare(pa){
    // Button 'ClearAll' clicked.
    if(pa.tk.length == 0 && pa.st.length == 0){
      this.compareData = [];
      this.displayData = [];
      this.comparison = "";
      return false;
    }

    // Filter the topic
    if(pa.tk.length == 1 && pa.st.length == 0){
      this.filterTopic(pa.tk[0]);
      return false;
    }

    this.selectedTopics = pa.tk;
    this.selectedStates = pa.st;

    if(this.selectedTopics.length == 0){
      this.alertifyService.warning("Please check at least one topic to start.");
      return;
    }
    if(this.selectedStates.length == 0){
      this.alertifyService.warning("Please check at least one jurisdictions to compare.");
      return;
    }

    if(this.selectedTopics.length > 15){
      this.alertifyService.confirmOk("The amount of information requested is causing the report to time out.  Please reduce your filter criteria.  You can download multiple comparisons with a smaller set of filter criteria and consolidate them into a single spreadsheet if you want to compare large amounts of data.");
      return;
    }

    var rbkeys: number[] =  [];
    var rulebookCompare = new RulebookCompare();

    this.selectedTopics.forEach(x => {
      var itemKey = rulebookCompare.getRbkey(x);
      if(itemKey !== 0) rbkeys.push(itemKey);
    });

    this.loading = true;

    this.dataService.regCompare(this.selectedStates, rbkeys).subscribe(
      res => {
        this.loading = false;
        this.compareData = this.mappingCompareData(res);
        this.displayData = this.compareData;
      },
      err =>{
        this.loading = false;
        console.error("got an error:" + err.error);
        this.alertifyService.error("server is not ready.");
      }
    );
  }

  filterTopic(topic: string){
    if(this.compareData.length == 0) return false;

    if(this.displayData.length === 1 && this.displayData[0].rb === topic){ // Filtered topic canceled.
      this.displayData = this.compareData;
    }else{
      this.displayData = this.compareData.filter(x => x.rb == topic);
    }
  }

  mappingCompareData(data: RbkeyStateDiffs[]){
    var result: RbStateDiffs[] = [];
    var rulebookCompare = new RulebookCompare();

    data.forEach(x => {
      var item: RbStateDiffs = {
        rb: rulebookCompare.getRbName(x.rbkey),
        rbState: this.mappingRbState(x.rbState)
      };
      result.push(item);
    });
    return result;
  }
  printReg(){
    window.print();
  }

  exportexcel(): void {
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    var widthColumn = [{width: 25},{width: 60}];
    this.selectedStates.forEach(x => {
      widthColumn.push({width: 60});
    });
    ws['!cols'] = widthColumn;
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    for(var i in ws){
      if (typeof(ws[i]) != "object") continue;
      let cell = XLSX.utils.decode_cell(i);
      ws[i].s = { // styling for all cells
        font: {
          name: "arial"
        },
        alignment: {
            vertical: "center",
            horizontal: "left",
            wrapText: true, // any truthy value here
        },
        border: {
            right: {
                style: "thin",
                color: "000000"
            },
            left: {
                style: "thin",
                color: "000000"
            },
        }
      }
      if (cell.r == 0 ) { // first row

        ws[i].s = { // bottom border
            font: {
              sz: 18,
              bold: true,
              color: {rgb: "363895"},
            },
            border: {
              right: {
                  style: "thin",
                  color: "000000"
              },
              left: {
                  style: "thin",
                  color: "000000"
              },
          }
        };
        ws[i].s.fill = { // background color
          patternType: "solid",
          fgColor: { rgb: "b2b2b2" },
          bgColor: { rgb: "b2b2b2" }
        };
      }
    }

    /* save to file */
    if(this.comparison){
      XLSX.writeFile(wb, this.comparison + "-" + new Date().toJSON().slice(0,10) + ".xlsx");
    }else{
      XLSX.writeFile(wb, "regcompare-" + new Date().toJSON().slice(0,10) + ".xlsx");
    }
  }

  async onSaveComparison(){
    const result = await this.openSaveComparisonDialog(this.comparison);

    if(result !== null) {
        this.comparison = result;
        console.log("save comparison: " + this.comparison);
        this.SaveComparison(this.comparison);
    }
  }

  onRunRegCompare(comparisonKey){
    var index = this.comparisonDef.findIndex(x => x.id == comparisonKey.id);
    if(index == -1) return;
    let pa = {st: this.comparisonDef[index].states.split(','), tk: this.comparisonDef[index].topics.split(',')};
    this.comparison = this.comparisonDef[index].comparisonName;
    this.comparisonId = this.comparisonDef[index].id;
    this.onRegCompare(pa);

  }

  onDeleteRegCompare(comparisonKey){
    console.info("child call to delete comparison: " + comparisonKey.cKey);
    this.dataService.DeleteRegComparison(comparisonKey.cKey).subscribe({
      next: (v) =>{
        var index = this.comparisonDef.findIndex(x => x.id == comparisonKey.cKey);
        if(index !== -1)
          this.comparisonDef.splice(index, 1);
        this.alertifyService.deleteSuccess("Comparision definition deleted.");
      }
    })
  }

  onListRegCompare(){
    let account = localStorage.getItem("account");
    var userid = 0;
    if(account) {
      userid = JSON.parse(account).id;
    }
    if(userid == 0) return;
    this.loading = true;
    this.dataService.getRegComparison(userid.toString()).subscribe({
      next: (v) =>{
        this.comparisonDef = v;
      },
      error: (e) =>{
        this.loading = false;
        this.alertifyService.warning(e);
      },
      complete: () => this.loading = false,
    });
  }

  SaveComparison(comparisonDef: string){
    if(comparisonDef == undefined) return;
    let account = localStorage.getItem("account");
    var userid = 0;
    if(account) {
      userid = JSON.parse(account).id;
    }

    let cd: ComparisonDef = {
      id: this.comparisonId,
      userId: userid,
      comparisonName: comparisonDef,
      topics: this.selectedTopics.join(','),
      states: this.selectedStates.join(',')
    };

    this.dataService.saveComparison(cd).subscribe({
      next: (v) => {
        if(cd.id == 0){
          cd.id = v;
          this.comparisonDef.push(cd);
        }else{
          let c = this.comparisonDef.find(x => x.id == v);
          if(c !== undefined)
            c.comparisonName = comparisonDef;
        }
        this.alertifyService.addSuccess("Comparison definition saved.");
        console.log(v)
      },
      error: (e) => {
        this.alertifyService.error("Failed to save comparison definition: " + e.message);
        console.error(e)
      },
      complete: () => console.info('complete')
    });

  }

  async openSaveComparisonDialog(comparisonName: string){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = comparisonName
    dialogConfig.width = '600px';
    dialogConfig.height = '220px';
    dialogConfig.id = "saveComparison";
    const dialogRef = this.dialog.open(DialogSaveComparisonDialog, dialogConfig);

    return dialogRef.afterClosed()
    .toPromise()
    .then(result => {
      return Promise.resolve(result);
    });
  }


  mappingRbState(data: ParaStateDiffs[]){
    var result : ParaStateDiffs[] = [];

    data.forEach(x => {
      var item: ParaStateDiffs = {
        paraNum: x.paraNum,
        question: x.question,
        paraSd: this.combineStateDiff(x.paraSd)
      };
      result.push(item);
    });
    return result;
  }

  combineStateDiff(data: StateDiffs[]){
    var result: StateDiffs[] = [];

    this.selectedStates.sort().forEach(st => {
      var item: StateDiffs = {stateKey: st, stateDiff: ""};
      data.forEach(x => {
        if(x.stateKey == st){
          item.stateDiff += x.stateDiff;
          let ul_count = (x.stateDiff.match(/<ul>/g))?.length;
          for ( let i = 0; i < ul_count; i++){
            item.stateDiff += "</ul>"
          }
        }
      })
      if(item.stateDiff.length === 0){
        item.stateDiff = "<b>No state difference</b>"
      }
      result.push(item);
    });
    return result;
  }
}


@Component({
  selector: 'dialog-save-comparison-dialog',
  templateUrl: './dialog-save-comparison-dialog.html',
  styleUrls: ['./regcompare.component.css']
})
export class DialogSaveComparisonDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogSaveComparisonDialog>,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {}

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveComparison(): void {
    const myInput = (document.getElementById("cpName") as HTMLInputElement).value
    this.dialogRef.close(myInput);
  }
}

@Component({
  selector: 'regcompare-display',
  templateUrl: 'regcompare-display.html',
  styleUrls: ['./regcompare.component.css']
})
export class RegcompareDisplay {
  states: string[];
  rbs: string[];
  compareData: RbStateDiffs[];


  constructor(
    public dialogRef: MatDialogRef<RegcompareDisplay>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

  ngOnInit() {
      this.states = this.data.states;
      console.log("states: " + this.states.join(','));
      this.rbs = this.data.rbkeys;
      console.log("rbkey: " + this.rbs.join(','));
      this.compareData = this.data.compare;
      this.compareData.forEach(x => {
        console.log(x.rb);
      })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  printReg(){
    window.print();
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    ws['A1'].s ={
      font:{bold: true, color: "#F2F2F2"},
    }

    /* save to file */
    XLSX.writeFile(wb, "regcompare.xlsx");
  }
}