export const environment = {
  production: true,
  endpoint: `https://ah-backend-poc.stpehs.com/api/`,
  //authURL: `https://auth.stpehs.com/audithub-dev/login`,
  authURL: `https://auth.stpehs.com/audithub-dev2/login`,
  PROTOCOL_VIRTUAL_DIRECTORY: 'publications/',
  DOMAIN: `.stpehs.com`,
  StpDomain: `https://www.stpub.com`,
  AudithubDomain: `https://audithub-dev.stpehs.com`,
  RegHubDomain: 'https://reghub-stp-staging.stpehs.com',
  secretKey: `093425346907875356244547`,
  version: `1.0.73c`,
 // endpoint: `https://ah-backend-poc.stpehs.com/api/`,
  //authURL: `https://auth.stpehs.com/audithub-dev/login`,
  profileApiKey:'bac1fedabe9649e88285218640ce0504',
  cookieIdleMultiTab: 'activeAt',
  token:'STPHUB_TOKEN',
  companyName:'COMPANY_Name',
};