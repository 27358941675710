import { Component, OnInit, Injectable, Inject} from '@angular/core';
import {DataSource, SelectionChange, CollectionViewer} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, merge} from 'rxjs';
import {map} from 'rxjs/operators';
import { FlatTreeControl } from '@angular/cdk/tree';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


import { PublicationService } from '../../publication/publication.service';
import { PublicationModel, PublicationNode} from '../../publication/publication';
import { AlertifyService } from '../../_models/alertify.service';

/** Flat node with expandable and level information */
export class DynamicFlatNode {
  constructor(
    public item: string, // content to display.
    public index: string, // index key to retrieve its child node.
    public key: number,
    public level = 1,
    public expandable = false,
    public isLoading = false) {}
}


@Injectable()
export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  get data(): DynamicFlatNode[] {return this.dataChange.value;}
  set data(value: DynamicFlatNode[]){
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }
  currentNode: DynamicFlatNode = null; // The latest node that has benn expanded.

  constructor(private _treeControl: FlatTreeControl<DynamicFlatNode>, private dataService: PublicationService){
    this.dataService.setSelectedState("none");
  }

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]>{
    this._treeControl.expansionModel.changed.subscribe(change => {
      if((change as SelectionChange<DynamicFlatNode>).added || (change as SelectionChange<DynamicFlatNode>).removed){
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void{}

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>){
    if(change.added){
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if(change.removed){
      change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
    }
  }

  // When selected state changed, call this fuction to refresh the current node.
  refreshNode(node: DynamicFlatNode){
    //console.log("to refresh node: " + this.currentNode.key);
    if(this.currentNode == null) {
      return false;
    }

    if(this.currentNode.level > 1){ // For section or paragraph, refresh the contents because they are prone to state change.
      //console.log("reload node content for the state selection change.");
      this.toggleNode(node, false);
      this.currentNode = node;
      this.toggleNode(this.currentNode, true);
    }
  }

  /** Toogle the node, remove from display list */
  toggleNode(node: DynamicFlatNode, expand: boolean){
    const index = this.data.indexOf(node);

    if (!expand) {
      let count = 0;
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++){

      }
      this.currentNode = null;
      this.data.splice(index + 1, count);
      this.dataChange.next(this.data);
    }else{
      /* -- comment temprorily because it does not work well
      if(this.currentNode !== null && this.currentNode.level == 0 )
        this.collapseNode(this.currentNode);
      */
      node.isLoading = true;
      this.dataService.getChild(node.index, node.key, node.level).subscribe(
        children => {
          if(!children || index < 0){ // If no children, or cannot find the node, no op
            node.isLoading = false;
            return;
          }
          if (expand) {
            //console.log(children);
            this.currentNode = node;
            //console.log("current node to expand: " + node.key + ", " + node.item );
            const nodes = children.map(obj => new DynamicFlatNode(this.dataService.decodeHtml(obj.name),'a', obj.id, node.level + 1, node.level < 3 ? true: false));
            this.data.splice(index + 1, 0, ...nodes);
          }

          node.isLoading = false;
          this.dataChange.next(this.data);
        },
      error => {
        node.isLoading = false;
        console.log(error);
      });
    }
  }

  collapseNode(node:DynamicFlatNode){
    const index = this.data.indexOf(node);
    let count = 0;
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++){

      }
      this.currentNode = null;
      this.data.splice(index + 1, count);
      this.dataChange.next(this.data);
  }

  addSubNode(index:number,name:string, key: number, isExpandable:boolean)
  {
    const node = this.data[index];
    const dfn = {
       item: name,
       index: "a",
       key: key,
       level:node.level+1,
       expandable:isExpandable,
       isLoading:false
    }
    this.data.splice(index + 1, 0, ...[dfn]);
    this.dataChange.next(this.data);
  }

  addNode(index:number,name:string, key: number, isExpandable:boolean)
  {
     const node = this.data[index];
     const dfn = {item: name,
      index: 'a',
        key: key,
         level:node.level,
         expandable:isExpandable,
         isLoading:false}
     this.data.splice(index + 1, 0, ...[dfn]);
     this.data=[...this.data]
//         this.dataChange.next(this.data);
  }

  changeNode(index:number,name:string)
  {
     this.data[index].item = name;
  }
}


@Component({
  selector: 'app-pocket-ref',
  templateUrl: './pocket-ref.component.html',
  styleUrls: ['./pocket-ref.component.css']
})
export class PocketRefComponent implements OnInit {
  userId: number;
  searchWord: string;
  selectedState: string;
  stateList: string[];

  constructor(public dataService: PublicationService,
    public dialog: MatDialog,
    private alertifyService: AlertifyService) {
    this.userId = JSON.parse(localStorage.getItem("account")).id;
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
   }

   treeControl: FlatTreeControl<DynamicFlatNode>;
   dataSource: DynamicDataSource;
   getLevel = (node: DynamicFlatNode) => node.level;
   isExpandable = (node:DynamicFlatNode) => node.expandable;
   hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;
   originData: PublicationModel[];

  ngOnInit() {
    this.dataService.showLoadingAnimation(true);

      this.stateList = this.getAuthorizedState();
      this.stateList.splice(0, 0, "None");


    this.selectedState = this.dataService.getSelectedState();

    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this.treeControl, this.dataService);
    this.dataService.getPublications(this.userId).subscribe(
      res =>{
        this.originData = res;
        this.dataSource.data = this.initialData(res);
        this.dataService.showLoadingAnimation(false);
      },
      err => {
        console.error("got an error: " + err.error);
        this.dataService.showLoadingAnimation(false);
        this.alertifyService.error(err.statusText);
      }
    );
  }

  getAuthorizedState(): string[]{
    var authorized_state: string[] = [];
    let permission = sessionStorage.getItem("auditHubPermissions");
    if(permission == null || permission == undefined) return authorized_state;

    let eafSd = JSON.parse(permission).eaf;
    let ofSd = JSON.parse(permission).of;

    if(eafSd && eafSd.trim().length > 0){
      authorized_state = authorized_state.concat(eafSd.toUpperCase().split(","));
    }
    if(ofSd && ofSd.trim().length > 0){
      authorized_state = authorized_state.concat(ofSd.toUpperCase().split(","));
    }
    if(authorized_state.length == 0) return authorized_state;
    return authorized_state.filter((v,i,a) => a.indexOf(v) === i).sort();
  }

  // Event listener on button 'Guide note'
  onDisplayGuidenote(parakey: number) : void{
    this.dataService.getGuidenote(parakey).subscribe(
      res =>{
        if(res.guidenotes == null || res.guidenotes == "") res.guidenotes = "No guide notes availabe.";
        let tmp = [res.paraNum, this.dataService.decodeHtml(res.guidenotes)];
        const dialogRef = this.dialog.open(GuidenoteDisplayDialog, {
          width: '800px',
          data: tmp
        });

        dialogRef.afterClosed().subscribe(result => {
        });
      }
    );
  }

  // Event listerner on Select: state difference
  onSelectStateChange(stateSelector){
    this.dataService.setSelectedState(stateSelector.value);
    if(this.dataSource.currentNode){  // If there is an expanded node, refresh the node.
      this.dataSource.refreshNode(this.dataSource.currentNode);
    }
  }

  searchThis(filterText: string){
    var filterData: PublicationModel[] = [];
    filterText = filterText.toUpperCase();

    for(let x of this.originData){
      let index = x.acronym.indexOf(filterText);
      if(index != -1){
        filterData.push(x);
        continue;
      }
      index = x.title.toUpperCase().indexOf(filterText);
      if(index != -1){
        filterData.push(x);
        continue;
      }
      index = x.version.toString().indexOf(filterText);
      if(index != -1){
        filterData.push(x);
        continue;
      }
    }

    this.dataSource.data = this.initialData(filterData);
  }

  /** Initial data from backend */
  initialData(data:PublicationModel[]): DynamicFlatNode[]{
    // filter the duplicate acronym, keeping the latest version only.
    data = data.filter((v, i, a) =>
      a.findIndex(v2 => (v2.acronym === v.acronym && v2.version > v.version)) === -1
    );

    data = data.filter((v,i,a) => v.category !== "ARCHIVE");
    // For Internatioal Protocol like "", only keep the country name.
    data.forEach(function(item, index, object)  {
      if(item.category === "CAN-MEX" || item.category === "IP") item.category = "1"; // TO order IP items on the top.
      else item.category == "2";
      if(item.title.startsWith("Environmental, Health & Safety Audit Protocol")){ // to keep the country name on te title only.
        let pointer = item.title.indexOf("- ");
        if(pointer != -1){
          item.title = item.title.substring(pointer + 1);
        }
      }
    });

    var index = data.findIndex(x => x.title.includes("State Differences"));
    while(index !== -1){
      data.splice(index, 1);
      index = data.findIndex(x => x.title.includes("State Differences"));
    }
    // order by category, then acronym.
    data.sort((a,b) =>
      (a.category.localeCompare(b.category)) || a.title.localeCompare(b.title));

    return data.map(x => new DynamicFlatNode(x.title, x.acronym , x.version, 0, true));
  }
}


@Component({
  selector: 'guidenote-display',
  templateUrl: 'guidenote-display.html',
  styleUrls: ['./pocket-ref.component.css']
})
export class GuidenoteDisplayDialog {

  constructor(
    public dialogRef: MatDialogRef<GuidenoteDisplayDialog>,
    @Inject(MAT_DIALOG_DATA) public data: string[]) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}