export const OAuthSettings = {
  appId: '5a82c7f8-9739-4bbe-899f-d13e9c82dc3f',
  client_secret:'',
  redirectUri: 'https://audithub-dev.stpehs.com',
  scopes: [
    "user.read",
    "mailboxsettings.read",
    "calendars.readwrite"
  ]
};

// Ref: https://docs.microsoft.com/en-us/graph/tutorials/angular?tutorial-step=3