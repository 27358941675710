import { Component, OnInit, Input, Output,EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { Observable,BehaviorSubject } from 'rxjs';

import { AppSect } from '../../model/TemplateRulebook';
import { TemplateService } from '../../service/template.service';

@Component({
  selector: 'app-answer-question',
  templateUrl: './answer-question.component.html',
  styleUrls: ['./answer-question.component.css']
})
export class AnswerQuestionComponent implements OnInit, OnChanges {
  @Input() appSect$: Observable<AppSect[]>;
  @Input() rbName: string;
  @Input() editable: boolean;
  @Output() answerApp = new EventEmitter<string>();

  appSects: BehaviorSubject<AppSect[]>;
  isLoading = false;

  constructor(private service: TemplateService) {
    this.appSects = new BehaviorSubject(null);
   }

  // to get the @input data from parent.
  ngOnInit() {
    this.appSect$.subscribe(data =>  {
      this.appSects.next(data);
    });
    this.service.isLoading$.subscribe(data =>{
      this.isLoading = data;
    });
  }

  // To refresh the data if @input from the parent has been changed.
  ngOnChanges(){
    this.appSect$.subscribe(data =>  {
      this.appSects.next(data);
    });
  }

  getAppSects(){
    return this.appSects;
  }

  setAppSects(aps: AppSect[]){
    this.appSects.next(aps);
  }

  getAnswer(){
    var answer = "";
    this.getAppSects().value.forEach( x => {
      answer += x.answerQ;
      if(x.answerE !== "0"){
        answer += x.answerE;
      }
    });
    return answer;
  }

  // Answer will be one of Y/N/I
  answerToAll(answer: string){
    //console.log("answerToAll(): answer = " + answer);
    this.appSects.value.forEach( v => {
      if(v.rbSectNum > 0){ // Skip the applicability question for rulebook
        v.answerQ = answer;
        //console.log("set for " + v.rbSectNum + " to " + answer);
        if(v.answerE != "0"){
          v.answerE = this.resetExemptionAnswer(answer);
        }
      }
    });

    this.setAppSects(this.appSects.value);
    //this.appSect$ = this.getAppSects();
    this.isLoading = true;
    this.answerApp.emit(this.getAnswer());
  }

  //
  resetExemptionAnswer(answer: string){
    if(answer == "Y")
      return "n";
    return "i";
  }

  // Answer rulebook applicable question
  answerRbAppQ(index: number, answer: string){
    this.appSects.value[index].answerQ = answer;
    this.setAppSects(this.appSects.value);
    this.appSect$ = this.getAppSects();
    this.isLoading = true;
    this.answerApp.emit(this.getAnswer());
  }

  answerRbAppE(index: number, answer: string){
    //console.log(index + ": " + answer);
    this.appSects.value[index].answerE = answer;
    this.setAppSects(this.appSects.value);
    this.appSect$ = this.getAppSects();
    this.isLoading = true;
    this.answerApp.emit(this.getAnswer());
  }
}