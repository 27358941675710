import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { merge, Observable, of as observableOf } from 'rxjs';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { switchMap, startWith, map, tap } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import * as fileSaver from 'file-saver';

import { PublicationDataSource } from './publication.datasource';
import { AlertifyService } from '../_models/alertify.service';
import { PublicationService } from './publication.service';
import { Publication } from './publication';
import {environment} from '../../environments/environment';
import { HelpInfoDialog} from '../_components/header/header.component';
import {UserProfile} from '../_models/account';
import jwt_decode from "jwt-decode";
import { CookieService} from 'ngx-cookie-service';

interface Category {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-publication',
  templateUrl: './publication.component.html',
  styleUrls: ['./publication.component.css']
})
export class PublicationComponent implements OnInit, AfterViewInit {
	dataSource: PublicationDataSource;
	displayedColumns: string[] = [ 'title', 'version','lastUpdate','pubId', 'formatAndPath','disabled','category', 'updating'];
	publications: Publication[] = [];
  userId: number;
  categories: Category[] = [
    {value: 'BUS', viewValue: "Business & Legal"},
    {value: 'CAN-MEX', viewValue: "Canada/Mexico"},
    {value: 'ENV', viewValue: "Environmental"},
    {value: 'IP', viewValue: "International"},
    {value: 'ISO', viewValue: "ISO"},
    {value: 'OSHA', viewValue: "Health & Safety"},
    {value: 'RISK', viewValue: "Risk Management"},
    {value: 'TRAINING', viewValue: "Training"},
    {value: 'TRANSPORTATION', viewValue: "Transportation"},
    {value: 'ARCHIVE', viewValue:"Archive"}
  ];
  selectedCat = "";
  expandedAcronym = "";
	resultsLength = 0;
  isRateLimitReached = false;
  searchWord: string;
  navigationSubscription;
  notFound = false;
  previousSearchWord: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
  	private service: PublicationService,
    private alertifyService: AlertifyService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public cookieService: CookieService,
    private router: Router
  	) {
    this.userId = JSON.parse(localStorage.getItem("account")).id;
  }

  initialiseInvites(){
    this.searchWord = "";
    this.selectedCat = "ENV";
    this.dataSource.getNumber().subscribe(re => this.resultsLength = re);
    this.dataSource.resetResultNumber();
    //this.paginator.pageIndex = 0;
    this.dataSource.loadPublications(this.userId, this.selectedCat, 0, 50);

  }

  ngOnInit() {
    this.publications = this.route.snapshot.data["publication"];
    this.dataSource = new PublicationDataSource(this.service, this.alertifyService);
    this.dataSource.getNumber().subscribe(re => this.resultsLength = re);
    this.dataSource.loadPublications(this.userId, this.selectedCat, 0, 50);
    this.dataSource.getCategory().subscribe(re =>{ // set the publication categroy the customer subscribes.
      if(re.length > 0){
        for( var i = 0; i < this.categories.length; i++){
          if(!re.includes(this.categories[i].value)){
           this.categories.splice(i, 1);
            i--;
          }
        }
      }
    });
  }

  ngAfterViewInit() {
  	this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

  	merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadPublicationPage(this.userId, this.selectedCat))
      ).subscribe((data) => {
        //console.log("publication: " + JSON.stringify(data));
      });
  }


  /**
   * The client click 'Purchase edition' for the particular version. Call backend to send notification email to info@stpub.com.
   * @param edition ARG|2020
   */
  purchase(edition: string){
    try{
      // Collect the purchasing infomation
      let token = this.getCookie('STPHUB_TOKEN');
      let decoded : UserProfile = jwt_decode(token);
      let idx = edition.indexOf("|");
      var message = "Please note that the client " + decoded.name + "(email: " + decoded.signInName + ") from " + decoded.CompanyName
        + " requested to purchase product: " + edition.substring(0, idx)
        + " " + edition.substring(idx + 1, idx + 5);
      this.service.sendEmail(message).subscribe(
        res =>{
          // do nothing.
        },
        error =>{
          console.error(error.message);
        }
      );
    }catch{

    }

    this.alertifyService.success("Thank you! Your Account Manager will contact you shortly to process.");
  }

  // When search keytext is cleared, reload the current category.
  cancelSearch(){
    if(!this.searchWord || this.searchWord.length === 0){
      this.dataSource.resetResultNumber();
      this.paginator.pageIndex = 0;
      this.dataSource.loadCategory(this.selectedCat,this.paginator.pageIndex, this.paginator.pageSize);
    }
     return false;
  }

  searchInputEvent(inputValue: string): void{
    if(this.notFound && inputValue.length >= this.previousSearchWord.length) {
      return; // It's no point to searh further.
    }
    this.previousSearchWord = inputValue;
    this.searchProduct();
  }

  searchProduct(){

    if(!this.searchWord || this.searchWord.length === 0 || this.searchWord.trim() === ""){
      this.notFound = false;

      return false;
    }
    this.dataSource.searchPublication(this.searchWord, this.selectedCat, this.paginator.pageIndex, this.paginator.pageSize);
    if( this.dataSource.searchPublication(this.searchWord, this.selectedCat, this.paginator.pageIndex, this.paginator.pageSize) === -1){
      this.notFound = true;
      this.alertifyService.warning("Not found.");
      return false;
    };
    this.notFound = false;
  }


  // Given a cookie key `name`, returns the value of
// the cookie or `null`, if the key is not found.
getCookie(name: string): string {
	const nameLenPlus = (name.length + 1);
	return document.cookie
		.split(';')
		.map(c => c.trim())
		.filter(cookie => {
			return cookie.substring(0, nameLenPlus) === `${name}=`;
		})
		.map(cookie => {
			return decodeURIComponent(cookie.substring(nameLenPlus));
		})[0] || null;
}

expandArchive(arconym: string, title: string){
      this.dataSource.toggleArchive(arconym, title);
  }


loadPublicationPage(user: number, category: string){
  	this.dataSource.loadPublicationPage(this.paginator.pageIndex, this.paginator.pageSize);
  }

hasState(pid: number): boolean{
  let permission = sessionStorage.getItem("auditHubPermissions");
  if(permission == null || permission == undefined) return false;

  if(pid == 396){
    let sd = JSON.parse(permission).of;
    if(sd && sd.trim().length > 0 && sd.trim().length < 140)
      return true;
  }
  if(pid == 397){
      let sd = JSON.parse(permission).of;
      if(sd && sd.trim().length > 0 && sd.trim().length < 140)
      return true;
  }
  return false;
}

getStates(pid: number): string[]{
  let permission = sessionStorage.getItem("auditHubPermissions");
  if(permission == null || permission == undefined) return [];

  if(pid == 396){
    let sd = JSON.parse(permission).of;
    if(sd && sd.trim().length > 0 && sd.trim().length < 140)
      return sd.toUpperCase().split(",");
  }
  if(pid == 397){
      let sd = JSON.parse(permission).eaf;
      if(sd && sd.trim().length > 0 && sd.trim().length < 140)
      return sd.toUpperCase().split(",");
  }
  return [];
}

  /**
   * Display dialog for help information. HelpInfoDialog is defined
   * @param evt
   * @param idx
   */
  openHelpInfo(evt: MouseEvent, idx: number): void {
    const target = new ElementRef(evt.currentTarget);
    this.dialog.open(HelpInfoDialog, {
      width: '280px',
      data: {
        trigger: target,
        helperIndex: idx
      }
    });
  }

  onSelectCategory(category: any){
    this.searchWord = "";
    this.selectedCat = category.value;
    this.dataSource.resetResultNumber();
    this.paginator.pageIndex = 0;

    this.dataSource.loadCategory(this.selectedCat, 0, this.paginator.pageSize)
  }

  // click state item to open the pdf file.
  onSelectStatePdf(pubId: number, acronym: string, state: any){
    this.openPublication(pubId, state.value, "pdf", acronym + "-" + state, "", acronym);
  }

  // click a state item to open the state excel file.
  onSelectStateExcel(pubId: number, state: string){
    this.service.getPdfUrl(pubId.toString(), state).subscribe(
      res => {
        for(let val of res){
          if(val.Format == 'xls'){
            let url = this.getProtocolVirtualDirectory() + val.Path;
            this.service.downloadFile(url).subscribe({
              next: (value: Blob) => {
                this.dataSource.showLoadingAnimation(false);
                let blob:any = new Blob([value], {type: 'application/zip'})
                fileSaver.saveAs(blob);
              },
              error: (error: any) => {
                console.error("error: " + error);
                this.dataSource.showLoadingAnimation(false);
                this.alertifyService.warning("Sorry, cannot download the file.");
              },
              complete: () => {
                // handle the completion
              }
            });
          }
        }
      }
  )


  }

  getProtocolVirtualDirectory(): string {
    return environment.PROTOCOL_VIRTUAL_DIRECTORY;
  }

  openPublication(pubId: number, version:string, format: string, title: string, path: string, arconym: string){
    // Call backend to mark this action for future analysis.
    this.service.markPublicationView(pubId, version, format, title, arconym).subscribe(
      res =>{
        // For pdf, open it with a new window; for others, download it directly.
        if(format == 'pdf' && title.indexOf('(Download)') == -1){// for 'download' version, we can't open it directly.
          //window.open('/publication/show/' + pubId + '/' + version + '/' + format);
          var win = window.open();
          win.location.href = '/audithub/publication/show/' + pubId + '/' + version + '/' + format;
        } else {
          var url = "";
          this.dataSource.showLoadingAnimation(true);
          url = this.getProtocolVirtualDirectory() + path;

          if(format == 'tasklists'){
                window.open(url, '_blank');
          }else{
                this.service.downloadFile(url).subscribe(
                res => {
                  this.dataSource.showLoadingAnimation(false);
                  let blob:any = new Blob([res], {type: 'application/zip'})
                  fileSaver.saveAs(blob);
                },
                err =>{
                  //console.log("error: " + err);
                  this.dataSource.showLoadingAnimation(false);
                  this.alertifyService.warning("Sorry, cannot download the file.");
                });
          }
        }
      },
       err =>{
        console.log("return error.");
       }
    );

  }
}