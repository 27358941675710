<div class="spinner-container" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>


<div class="rc-content">
  <div id="condition" class="sticky-sidebar">
    <div class="shadow p-0">
      <div class="bs-canvas bs-canvas-anim bs-canvas-left"><!-- position-sticky-->
          <!-- Off-canvas sidebar markup, left/right or both. -->
        <app-rc-search-filter
          [comparisonDef] = this.comparisonDef
          (applyCompareEvent) = "onRegCompare($event)"
          (listComparisonDefEvent) = "onListRegCompare()"
          (runComparisonDefEvent) = "onRunRegCompare($event)"
          (deleteComparisonDefEvent) = "onDeleteRegCompare($event)">
        </app-rc-search-filter>
      </div>
    </div>
  </div>

  <div id="result" class="main">
    <div class="main-header">
      <h5>{{comparison}}</h5>
      <div class="button-row">
        <button mat-button (click) = "onSaveComparison()" [disabled] = "compareData.length === 0">
          <mat-icon>save</mat-icon>
        </button>
        <button mat-button (click) = "exportexcel()" [disabled] = "compareData.length === 0">
          <mat-icon>file_download</mat-icon>
        </button>
      </div>
    </div>
    <div class="table-print" *ngIf = "compareData == null || compareData.length == 0 ">
      <h3>RegCompare Instructions</h3>
      <ul>
        <li><p>Select the jurisdictions and topics you want to compare with federal requirements. You can select up to 5 jurisdictions and 15 topics to compare. Click <b>Apply</b> to run the comparison. The comparison results are organized into rows by topic and into columns by jurisdiction. If you have chosen more than one topic, you can further refine the results by selecting one of the topics under the topic filter. This allows you to focus on a single topic in the results. If there are no state differences for a topic, we do not include that row in the results.</p></li>
        <li><p>To save your comparison for future use, click the disk icon in the top right corner of the screen. Enter a comparison name and click <b>Save</b>. You can access your saved comparisons by expanding the <b>Comparison Name</b> drop down in the filter panel and clicking <b>Run a Saved Comparison</b>.</p></li>
        <li><p>To export the results, select the download icon in the top right corner of the screen. This will download the results to an Excel file which allows you to share the results with others, print, or conduct further analysis.</p></li>
      </ul>
    </div>
    <div class="table-print" *ngIf = "compareData !== null && compareData.length > 0 ">
      <table class="mat-table regcompare" id="excel-table">
          <thead>
            <tr class="mat-header">
              <th class="t1">Topic</th>
              <th class="t2">Federal</th>
              <th class="t2" *ngFor = "let state of selectedStates">{{state}}</th>
            </tr>
          </thead>
          <tbody class="displayContents">
            <ng-container *ngFor = "let rbdata of displayData">
                <tr *ngFor = "let para of rbdata.rbState" class="mat-row">
                  <td class="t1">{{rbdata.rb}} -   {{para.paraNum}}</td>
                  <td class="t2" [innerHtml] = "para.question"></td>
                  <ng-container *ngFor = "let st of para.paraSd">
                    <td class="t2" [innerHtml]="st.stateDiff"></td>
                  </ng-container>
                </tr>

            <ng-container *ngIf = "rbdata.rbState.length === 0">
              <tr  class="mat-row">
                <td class="t1">{{rbdata.rb}}</td>
                <td class="t2">All same</td>
                <ng-container *ngFor = "let st of selectedStates">
                  <td class="t2">All same</td>
                </ng-container>
              </tr>
          </ng-container>
        </ng-container>
          </tbody>
      </table>
    </div>
  </div>

</div>