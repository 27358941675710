<!-- Leftside panel starts here -->

<div class="left-section filter">
  <div class="form-row nav flex-column p-1">
    <div class="wrapper">
          <div class="main-part">
                <button
                    class="btn btn-sm btn-success btn-block"
                    type="button"
                    [disabled]="selectedStates.length === 0 || selectedTopics.length === 0 || loading === true"
                    (click)="onRegCompare()">Apply
                </button>
          </div>
          <div class="minor-part">
            <button mat-button  (click)="ClearAll()">Clear All
            </button>
          </div>
    </div>

    <div class="cheader">
          <div class="d-flex justify-content-between box-header text-white">
            <div>
              <p class="triangle-bottomleft"><span class="fa fa-search mr-2" aria-hidden="true"></span>Compare</p>
            </div>
            <div class="align-self-end  p-2">

              <p-overlayPanel #op [dismissable]="true" [showCloseIcon]="true" [appendTo]="'body'" my="right bottom" at="left bottom" [style]="{width: '60%'}">
                <div class="panel panel-info">
                  <div class="panel-heading">
                    <h3 class="panel-title">RegCompare Introduction</h3>
                  </div>
                  <div class="panel-body">
                          <p>Select the jurisdictions and topics you want to compare with federal requirements.  You can select up to 5 jurisdictions and 15 topics to compare.  Click <b>apply</b> to run the comparison.  The comparison results are organized into rows by topic and into columns by jurisdiction. If you have chosen more than one topic, you can further refine the results by selecting one of the topics under the topic filter.  This allows you to focus on a single topic in the results.  If there are no state differences for a topic, we do not include that row in the results.</p>
                          <p>To save your comparison for future use, click the disk icon in the top right corner of the screen.  Enter a comparison name and click Save.  You can access your saved comparisons by expanding the Comparison Name drop down in the filter panel and clicking Run a Saved Comparison.</p>
                          <p>To export the results, select the download icon in the top right corner of the screen.  This will download the results to an Excel file which allows you to share the results with others, print, or conduct further analysis.</p>
                  </div>
                </div>
              </p-overlayPanel>
              <mat-icon class="fa flag-info pointer" (click)="op.toggle($event)">info</mat-icon>
            </div>
          </div>
    </div>

    <div class="d-flex justify-content-between box-header text-white mb-1">
      <div class="triangle-bottomleft-remove compare-header">
        <mat-icon class="fa fa-info-circle pointer">filter_alt</mat-icon>&nbsp; Filter
      </div>
    </div>

    <div id="accordJur" class="position-relative" #accordJur>
      <div header="Jurisdiction" class="pointer-none">
        <div class="ui-accordion-header ui-state-default ui-corner-all">
          <div class="panel-body pt-0">
            <div class=" p-0 rc-item">
              <p>Jurisdictions</p>
              <div class="icon-right">
                <mat-icon class="viewall icon-right fa-flag f-size-14  flag-info" [hidden]="selectedStates.length == 0 ">flag</mat-icon>
                <mat-icon class="viewall icon-right fa-plus-square f-size-14 pointer  pointer-auto juris-info" (click)="showJurPopup()">add</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf = "selectedStates.length > 0" class="chosen-item">
        <p>{{selectedStates.join(", ")}}</p>
        <button button mat-button
        class="button-action" (click)="ClearJurisdiction()">Clear</button>
      </div>
    </div>

    <div id="accordTopic"  class="position-relative" #accordTopic>
      <div header="Topic" class="pointer-none">
        <div class="ui-accordion-header ui-state-default ui-corner-all">
          <div class="panel-body pt-0">
            <div class="p-0 rc-item">
              <p>Topics</p>
              <div class="icon-right">
                <mat-icon class="viewall icon-right fa-flag f-size-14  flag-info" [hidden]="selectedTopics.length == 0 ">flag</mat-icon>
                <mat-icon class="viewall icon-right fa-plus-square f-size-14 pointer  pointer-auto juris-info" (click)="showTopicPopup()">add</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf = "selectedTopics.length > 0" class="chosen-item">
        <ul>
          <ng-container *ngFor = "let tp of selectedTopics;let i = index">
            <li *ngIf = "i < 16" (click) = "filterTopic(tp)" class="clickable" [ngClass] = "filteredTopic === tp ? 'filteredTopic': ''">{{tp}}</li>
            <li *ngIf = "i === 16">...</li>
          </ng-container>
        </ul>
        <button mat-button
          class="button-action" (click)="ClearTopic()">Clear</button>
      </div>
    </div>

    <!-- div id="accordTopic"  class="position-relative" #accordTemplate>
      <div header="Topic" class="pointer-none">
        <div class="ui-accordion-header ui-state-default ui-corner-all">
          <div class="panel-body pt-0">
            <div class="p-0 rc-item">
              <p>Templates</p>
              <div class="icon-right">
                <mat-icon class="viewall icon-right fa-flag f-size-14 position-absolute flag-info" [hidden]="true">flag</mat-icon>
                <mat-icon class="viewall icon-right fa-plus-square f-size-14 pointer position-absolute pointer-auto juris-info" [ngClass]="{'disable':true}">add</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf = "false" class="chosen-item">
        <ul>
          <ng-container *ngFor = "let tp of selectedTopics;let i = index">

          </ng-container>
        </ul>

          <button mat-button
          class="button-action" (click)="ClearTopic()">Clear</button>
      </div>
    </div-->

    <div class="d-flex justify-content-between box-header text-white mb-1">
      <div class="triangle-bottomleft-remove compare-header"
        [ngClass] = "this.selectedComparisonId == 0 ? 'disable': 'runnable'"
        (click) = "runComparisonDef()">
        <mat-icon
          class="fa fa-info-circle pointer"
          [ngClass] = "this.selectedComparisonId == 0 ? 'disable': 'runnable'"
          (click) = "runComparisonDef()">restore</mat-icon>&nbsp; Run a Saved Comparison
      </div>
    </div>

    <mat-accordion id="accordSaved"  class="position-relative" #accordSaved>
      <mat-expansion-panel (opened) =  "openComparisonDef()">
        <mat-expansion-panel-header class="ui-accordion-header ui-state-default ui-corner-all">
          <mat-panel-title class="panel-body pt-0">
            Comparison Name
          </mat-panel-title>
        </mat-expansion-panel-header>
        <input id="SavedSearchName" type="text"
          class="form-control form-control-sm mt-2 mb-1 input-lg"
          placeholder="Search ..."
          [(ngModel)]="searchWord"
          (input)="onSearchComparison($event.target.value)"
        />
        <div>
          <mat-radio-group>
          <ng-container *ngFor = "let cd of displayDef;let i = index">
            <div *ngIf = "i < 5" class="cdLine">
              <mat-radio-button class="radiobutton"
                [name] = "cd.comparisonName"
                [checked] = "isComparisonSelected(cd.id)"
                [value] = "cd.id"
                (change)="onComparisonSelected(cd.id)">{{cd.comparisonName}}</mat-radio-button>
              <mat-icon class="deleteButton material-icons-outlined" (click) = "onDeleteComparison(cd.id, cd.comparisonName)">delete</mat-icon>
            </div>
            <div *ngIf = "i === 5" class="cdLine">
              ...
            </div>
          </ng-container>
        </mat-radio-group>

        </div>
      </mat-expansion-panel>
    </mat-accordion>

</div>