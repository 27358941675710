import { Component, OnInit } from '@angular/core';
import jwt_decode from "jwt-decode";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CookieService} from 'ngx-cookie-service';

import  { AuthService } from '../auth/auth.service';
import { Account, AuditHubPermissionModel} from '../_models';
import {UserProfile, LoginValue} from '../_models/account';
import { localStorageKeys } from '../_constants';
import * as CryptoJS from 'crypto-js';
import {environment} from '../../environments/environment';
import { AppSettings} from '../_constants/AppSettings';
import { SessionTimeoutService } from '../_common/session-timeout-service';

import { UserProfileApiService, UserProfileSettings } from '@stp/_common/user-profile-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.css']
})
export class SsoComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public service: AuthService,
    private cookieService: CookieService,
    private sessionService: SessionTimeoutService,
    private userProfileAPI : UserProfileApiService,
    private httpClient: HttpClient)
    { }

  public ssoLoginUrl: string = this.getAuthUrl();
  public oldStpOnline: string = "https://stponline.stpehs.com/sso.asp?username=";
  public resetpwd: string = "https://auth.stpehs.com/audithub/resetpwd";

  // user in this list will stay in new audithub; others will be redirected to old stponline.
  //private testUsers: string[] = ['rafyz@stpub.com','roxanec@stpub.com','gaila@stpub.com','calvinl@stpub.com','katied@stpub.com','nataliee@stpub.com'];

  //public  ssoLoginUrl: string = "https://auth.stpehs.com/audithub-pentest/login/";

  ngOnInit() {
    let currentUrl = this.router.url;

    if(currentUrl === "/"){
      let hasCookie = this.isLoginCookieSet();

      if(hasCookie){ // use cookie to login automatically.
        let stpid = this.decrypt(this.cookieService.get('STPHUB_ID'));
        this.autoLogin(stpid);
      }else { // redirect to sso login url.
        window.location.href = this.ssoLoginUrl;
        SessionTimeoutService.resetCounter(this.cookieService);
      }
    }
    else{ // this page is called from the sso login page with token.
      this.ssoLogin();
    }
  }

   // To check if the cookies were set.
   isLoginCookieSet(): boolean{
    return this.cookieService.check('STPHUB_ID');
    //|| sessionStorage.getItem(environment.token) !== null;
  }

  /**
   * Login with the account saved in the cache.
   * @param stpid the email account
   * @returns
   */
  autoLogin(stpid: string) {
    //console.log("autoLogin() for " + stpid);

    if(!this.validateEmail(stpid)){
      // delete the cookie if it is not a valid account.
      this.deleteCookie("STPHUB_ID");
      this.router.navigateByUrl("login");
      return false;
    }

    let token = this.cookieService.get(environment.token);
    if(!token){
      window.location.href = this.ssoLoginUrl;
      SessionTimeoutService.resetCounter(this.cookieService);
    }
    if(!this.isExpectedUser(token)){
      window.location.href = this.oldStpOnline + stpid;
    }

    // if (sessionStorage.getItem("AuditHubPermissions") === null) {
    //     this.userProfileAPI.initializeUserProfileApiWithParams(this.route, () => {
    //       this.loginWithToken(token);
    //     }, token);
    // }
    if (sessionStorage.getItem(UserProfileSettings.auditHubPermissions) === null) {
      this.userProfileAPI.initializeUserProfileApi(this.route, () => {
        this.loginWithToken(token);
      });
    }
    else {
      // already has audithub perms
      this.loginWithToken(token);
    }

    //this.loginWithToken(token);
  }


  loginWithValue(authInfo: LoginValue) {
    this.service.showLoadingAnimation(true);
    this.service.login(authInfo)
    .subscribe(
      auth => {
        this.service.showLoadingAnimation(false);
        if (!auth.isSuccess) {
          console.log('auth failed:' + auth.messages[0].Key + ", " + auth.messages[0].Value);
          this.router.navigateByUrl("login");
          return false;
        }

        const user: Account = auth.user;
        localStorage.setItem(localStorageKeys.account, JSON.stringify(user));
        this.setStpIdCookie(authInfo.email);
        this.router.navigateByUrl("audithub/home");
      },
      err => {
        console.error("got an error:" + err.statusText);
        //this.loginErrorMessage = err.message;
        this.service.showLoadingAnimation(false);
        this.deleteCookie("STPHUB_ID"); // delete the cookie if it is not a valid account.
        this.router.navigateByUrl("login");
      }
    );
}


// use the URL fragment from SSO login page to login audit hub.
ssoLogin(){
    //:#id_token=eyJ0eXAiOiJKV....
    this.route.fragment.subscribe(
      fragment => {
      let ssoInfo = this.categorifySsoUrl(fragment); //token, reset, or forget password.

      if(ssoInfo === "token"){
        SessionTimeoutService.resetCounter(this.cookieService);
        let token = fragment!.substring(9); // to skip 'id_token='
        this.setCookie(token, "STPHUB_TOKEN");

        if (sessionStorage.getItem(UserProfileSettings.auditHubPermissions) === null) {
          this.userProfileAPI.initializeUserProfileApi(this.route, () => {
            this.loginWithToken(token);
          });
        } else {
          this.loginWithToken(token);
        }
        //this.loginWithToken(token);
      }else if(ssoInfo === "reset"){// forgot pwd
        window.location.href = this.resetpwd;
      }else if(ssoInfo === "change"){ // change pwd
        window.location.href = this.resetpwd;
      }else {
        window.location.href = this.ssoLoginUrl;
        SessionTimeoutService.resetCounter(this.cookieService);
      }
    })
  }

  // Login with token
  loginWithToken(token: string){
    let decoded : UserProfile = jwt_decode(token);
    // To redirect some test account to the new audit hub, and redirect others to old stponline.
    if(this.isExpectedUser(token) === true){
      let userType = this.getUserType(decoded);

      if(userType.length === 0 || userType.substring(1) == "00"){ // has no audit hub permission, redirec to about page.
        window.location.href = "https://www.stpub.com/about/#audithub";
        SessionTimeoutService.resetCounter(this.cookieService);
        return false;
      }

      const user: Account = {
        //id: +decoded.UserID,
        id: +sessionStorage.getItem(UserProfileSettings.userID),
        userId: +decoded.UserID,
        //companyId: +decoded.CompanyID,
        companyId: +sessionStorage.getItem(UserProfileSettings.companyID),
        companyName: decoded.CompanyName,
        firstName: "",
        lastName: "",
        name: decoded.name,
        email: decoded.STPID,
        userType: userType,
        emis: +decoded.EMISPartnerID,
        roles:[]
      };
      user.userType = userType;
      localStorage.setItem(localStorageKeys.account, JSON.stringify(user));
      try{
        this.setLoginCookies(token);
      }
      catch(e){
        console.error(e.message);
        window.location.href = this.ssoLoginUrl;
        SessionTimeoutService.resetCounter(this.cookieService);
      }

      let loginValue = {
        email: decoded.STPID,
        password: "fake-pwd",
        //userId: +decoded.UserID,
        userId: +(sessionStorage.getItem(UserProfileSettings.userID)),
        companyId: +sessionStorage.getItem(UserProfileSettings.companyID)
        //companyId: +decoded.CompanyID
      };

      this.login(loginValue);
    }else{
      window.location.href = this.oldStpOnline + decoded.STPID;
    }
  }

  setLoginCookies(token: string){
    if (this.cookieService.get(environment.token) === null) {
      this.setCookie(token, "STPHUB_TOKEN");
    }

    let decoded : UserProfile = jwt_decode(token);
    this.setStpIdCookie(decoded.STPID);
  }


  // Check the parameters sent by the SSO login page.
  categorifySsoUrl(fragment: string | null){
    if(fragment === null) return "";

    if(fragment.startsWith("id_token=")){ // login completed, receivd a token.
      return "token";
    }else if(fragment.indexOf("forgotten") > 0){ // forgot password.
      return "reset";
    }else if(fragment.indexOf("change") > 0){ // change password.
      return "change";
    }else if(fragment.indexOf("#error=access_denied") > 0){ // cancel reset password process
      return "cancel";
    }else{
      return "";
    }
  }


  /**
   * To check if it is expected user --
   * expected: true
   * not in expected list: false
   */
  isExpectedUser(token: string): boolean{
    return true;
  }

  setStpIdCookie(cookieValue: string){
    const key = environment.secretKey;
    const encrypt = this.encrypt(cookieValue);
    this.setCookie(encrypt, "STPHUB_ID");
  }

  getAuthUrl(): string{
    return environment.authURL;
  }

  /**
   * Call crypto-js to decrypt string.
   * @param encryptValue
   * @returns decryptd string
   */
  decrypt(encryptValue: string): string {
    //console.log("decrypt started for " + encryptValue);
    var key = CryptoJS.enc.Utf8.parse(environment.secretKey);
    var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);
    var decrypted = CryptoJS.AES.decrypt(encryptValue, key,  {iv: iv});
     return decrypted.toString(CryptoJS.enc.Utf8);
  }


  /**
   * Call crypto-js to encrypt string
   * @param value
   * @returns encrypted string
   */
  encrypt(value: string): string {
    //console.debug("encrypt() started. key: " + environment.secretKey+ "," + value);
    var key = CryptoJS.enc.Utf8.parse(environment.secretKey);
    var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);
    var encrypted = CryptoJS.AES.encrypt(value, key,  {iv: iv});
    //console.debug("after encode: |" + encodeURIComponent(encrypted)  +"|");
    return encodeURIComponent(encrypted.toString());
  }

  validateEmail(email: string){
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  setCookie(cookieValue: string, cookieName:string){
    let domain = environment.DOMAIN;
    let expiry = new Date();
    expiry.setDate(expiry.getDate() + 7);
    try{
      // this can write the cookie without subdomain
      document.cookie = cookieName + "=" + cookieValue + "; expires=" + expiry + "; path=/; Domain=" + domain + ";Secure;SameSite=Lax";
      /*this.cookieService.set(cookieName, cookieValue, expiry, path, domain, secure,'Lax');*/
    }catch(err){
      console.log("error: " + err.message);
    }
  }


  deleteCookie(cookie: string){
    let domain = environment.DOMAIN;
    try{
      document.cookie = cookie + "=test@test.com; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; Domain=" + domain + ";Secure";
      //this.cookieService.delete(cookie, "/", domain);
    }catch(err){
      console.error("error: " + err.message);
    }
  }

  /**
   * Get user permission from token.
   * @param permission
   * @returns "101"  first char: reghub; second: regapply; third: audithub basic
   */
  getUserType(permission: UserProfile): string {
    //var result = this.getPermission(permission.RegHubFeaturePermission);
    var perms = JSON.parse(sessionStorage.getItem(UserProfileSettings.regHubIntlPermissions));
    var result = this.getPermission(perms.reghub);
    if(result == "0" ){
      //result = this.getPermission(permission.RegHubIntlPermission); // check reghub content permission too.
      result = this.getPermission(sessionStorage.getItem(UserProfileSettings.regHubIntlPermissions));
    }
    //let auditPermission = JSON.parse(permission.AuditHubPermission);
    let auditPermission = JSON.parse(sessionStorage.getItem(UserProfileSettings.auditHubPermissions));
    if(auditPermission.feature === undefined){
      return "";
    }
    if(auditPermission.feature.includes("regapply")){ // have the permission for reg_apply.
      result = result.concat("1");
    }else{
      result = result.concat("0");
    }
    if(auditPermission.feature.includes("default")){ // have the permission for audithub_basic.
      result = result.concat("1");
    }else{
      result = result.concat("0");
    }
    return result;
  }

  getPermission(permissionJson: string | null): string{
    if(permissionJson == null || permissionJson.length < 3) return "0"; // empty json, only "{}"
    return "1";
  }


  login(authInfo: LoginValue) {
    this.service.showLoadingAnimation(true);
      this.service.login(authInfo)
        .subscribe(
          auth => {
            this.service.showLoadingAnimation(false);
            if (!auth.isSuccess) {
              console.log('auth failed:' + auth.messages[0].Key + ", " + auth.messages[0].Value);
              this.router.navigateByUrl("login");
              return false;
            }
            this.router.navigateByUrl("audithub/home");
          },
          err => {
            console.error("got an error:" + err.statusText);
            console.error(err.message);
            this.service.showLoadingAnimation(false);
            this.router.navigateByUrl("login");
          }
        );
  }
}