import { Component, ElementRef, Inject, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { Router} from '@angular/router';
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {DialogData} from '../../_models/dialogData';
import { AppSettings } from '../../_constants/AppSettings';
import { AuthService  } from '../../auth/auth.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() userEmail: string;
  @Input() user: string;
  @Input() title: string;
  @Output() openMenuEvent = new EventEmitter<string>();

  constructor(  private router: Router,
    private authService: AuthService,
    public dialog: MatDialog) { }

  ngOnInit() {
  }

  signOut(){
    this.authService.signOut();
  }

  clearCache(){
    sessionStorage.clear();
    let cacheTemplate = localStorage.getItem('template');
    if(cacheTemplate !== null) {
      localStorage.removeItem('template');
    }

    window.location.reload();
  }

  // Display AuditHub release notes.
  aboutAudit(evt: MouseEvent){
    const target = new ElementRef(evt.currentTarget);
    this.dialog.open(HelpInfoDialog, {
      width: '1480px',
      data: {
        trigger: target,
        helperIndex: 14,
      }
    });

  }

  openMenu(value: string){
    this.openMenuEvent.emit(value);
  }

  openHelpInfo(evt: MouseEvent, title: string): void {
    const target = new ElementRef(evt.currentTarget);
    var indx = 0; // indx: see definition at  ./_constants/AppSettings.ts
    if(title === "Product List"){
      indx = 0;
    }else if(title === "New Template") {
      indx = 3;
    } else if(title === "Pocket Reference") {
      indx = 8;
    } else if(title === "Custom Questions") {
      indx = 12;
    } else if(title === "Custom Ranking & Scoring") {
      indx = 13;
    }
    else {
      indx = 0;
    }

    this.dialog.open(HelpInfoDialog, {
      width: '280px',
      data: {
        trigger: target,
        helperIndex: indx,
        rightPosition: true
      }
    });
  }
}

@Component({
  selector: 'help-info-dialog',
  templateUrl: 'help-info-dialog.html',
  styleUrls: ['./header.component.css']
})
export class HelpInfoDialog implements OnInit {
  private readonly _matDialogRef: MatDialogRef<HelpInfoDialog>;
  private readonly triggerElementRef: ElementRef;
  public dialogData: DialogData
  constructor(_matDialogRef: MatDialogRef<HelpInfoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: {trigger: ElementRef, helperIndex: number, rightPosition: boolean}) {
      this._matDialogRef = _matDialogRef;
      this.triggerElementRef = data.trigger;
  }

  ngOnInit() {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();
    if(this.data.rightPosition == true) {// show dialog at right side
      matDialogConfig.position = { left: `${rect.right + 20}px`, top: `${rect.bottom + 20}px` };
    }
    else { // show dialog at left side
      matDialogConfig.position = { left: `${rect.left - 300}px`, top: `${rect.bottom + 20}px` };
    }
    this._matDialogRef.updateSize(matDialogConfig.width, matDialogConfig.height);
    this._matDialogRef.updatePosition(matDialogConfig.position);

    this.dialogData = AppSettings.HelpInfo[this.data.helperIndex].helper;
  }

  cancel(): void {
    this._matDialogRef.close(null);
  }

  closeHelper(): void {
    this._matDialogRef.close(null);
  }
}