import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { TemplateContent, AppSect, Rb, Rulebook} from '../model/TemplateRulebook';
import {TemplateService} from '../service/template.service';

@Component({
  selector: 'app-view-rulebooks',
  templateUrl: './view-rulebooks.component.html',
  styleUrls: ['./view-rulebooks.component.css']
})
export class ViewRulebooksComponent implements OnInit {
  csskey: number;
  auditName: string;
  rbkey: number;
  rulebook$: Observable<Rulebook>;
  selectedRbName: string;
  selectedAppkey: number;
  rulebookList: TemplateContent[];
  currentTopic: string;
  hideGuidenote = true;
  states: string[];
  hasState = false;
  selectedState: string;

  constructor (
    private route: ActivatedRoute,
    private router: Router,
    public service: TemplateService,
    public dialog:MatDialog) { }

  ngOnInit() {
    this.selectedRbName = this.service.decodeHtml(this.service.getRbName().value);
    this.service.getCurrent().subscribe(res =>{
      this.rulebookList = res;
    });

    this.auditName = this.service.getAuditName();
    this.route.paramMap.subscribe(
      paraMap => {
        this.csskey = +paraMap.get('csskey');
        let para = paraMap.get('rbkey');
        this.rbkey = +para.substring(0, para.indexOf('|'));
      }
    );

    this.hasState = this.hasStateDiff(this.rbkey);
    this.selectedState = this.service.getFavoriteState(this.csskey.toString());
    this.loadRulebookContents(this.csskey, this.rbkey, this.selectedState);
  }

  // Given a rulebook key, to get the Acronym.
  getAcronym(rbkey: number):string{
    for(var tc of this.rulebookList){
      for (var topic of tc.topics){
        const found = topic.rulebooks.find(x => x.rbKey == rbkey);
        if(found !== undefined)
          return tc.acronym;
      }
    }
    return "notfound";
  }

  // Given a rulebook key, to determine if there is state difference for the rulebook.
  hasStateDiff(rbkey: number): boolean{
    const acronym = this.getAcronym(rbkey);
    this.states = [];

    let permission = sessionStorage.getItem("auditHubPermissions");
    if(permission == null || permission == undefined) return false

    if(acronym.toUpperCase() == "EAF"){
      let sd = JSON.parse(permission).eaf;
      if(sd && sd.trim().length > 0) return true;
    } else if(acronym.toUpperCase() == "OF"){
      let sd = JSON.parse(permission).of;
      if(sd && sd.trim().length > 0) return true;
    }
    return false;
  }

  getAuthorizedState(acronym: string): string[]{
    var authorized_state: string[] = [];
    let permission = sessionStorage.getItem("auditHubPermissions");
    if(permission == null || permission == undefined) return authorized_state;
    let sd;
    if(acronym.toUpperCase() == "EAF"){
      sd = JSON.parse(permission).eaf;
    } else if(acronym.toUpperCase() == "OF"){
      sd = JSON.parse(permission).of;
    }

    if(sd && sd.trim().length > 0){
      authorized_state = authorized_state.concat(sd.toUpperCase().split(","));
    }
    if(authorized_state.length == 0) return authorized_state;
    return authorized_state.filter((v,i,a) => a.indexOf(v) === i).sort();
  }



  loadRulebookContents(csskey: number, rbKey: number, state?: string, custom?:boolean){
    try {
      this.service.showLoadingAnimation(true);

      this.rulebook$ =  this.service.getApplicableRulebookWithState(csskey, rbKey, state, custom??false);
      this.rulebook$.subscribe(
        res => {
          res.rbName = this.service.decodeHtml(res.rbName);
          res.summary = this.service.decodeHtml(res.summary);

          res.sect.forEach(element => {
            element.rbSectName = this.service.decodeHtml(element.rbSectName);
            if(element.sectionStateDifference){
              for(var i = 0; i < element.sectionStateDifference.length; i++){
                element.sectionStateDifference[i] = "<b class='highlight'>" + state + "- " +
                  this.service.decodeHtml(element.sectionStateDifference[i]).replace("State Difference:","State Difference:</b>");
              }
            }

            if(element.para != null){
              element.para.forEach( p =>{
                p.question = this.service.decodeHtml(p.question);
                p.guideNote = this.service.decodeHtml(p.guideNote);

                if(p.paraStateDifference){
                  for(var i = 0; i < p.paraStateDifference.length; i++){
                    p.paraStateDifference[i] = "<b class='highlight'>" + state + "- " +
                      this.service.decodeHtml(p.paraStateDifference[i]).replace("State Difference:","State Difference:</b>");
                  }
                }
              })
            }
          });
        },
        err => {
          console.error("got an error:" + err);
          this.service.showLoadingAnimation(false);
        },
        () => this.service.showLoadingAnimation(false)
      );
    }catch(Error){
      this.service.showLoadingAnimation(false);
      alert(Error.message);
    }
  }

  // Receive a click event from child component
  onClickRulebook(rbkey: number){
    this.selectedRbName = this.service.getRbName().value;
    this.rbkey = rbkey;
    this.hasState = this.hasStateDiff(this.rbkey);

    const states = this.getAuthorizedState(this.getAcronym(rbkey));
    if(this.selectedState && !states.includes(this.selectedState)){
      this.selectedState = "";
    }

    this.loadRulebookContents(this.csskey, rbkey, this.selectedState, this.getAcronym(rbkey) === "Custom");
  }

  // Hide or display the Guide Note.
  toggleGuidenote(){
    this.hideGuidenote = !this.hideGuidenote;
    this.service.setHideguidenote(this.hideGuidenote);
  }

  // Print Rulebook
  printRulebook(){
    window.print();
  }


  // Display state dialog.
  showState(){
    const state = this.service.getFavoriteState(this.csskey.toString());
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      selected: state,
      states: this.getAuthorizedState(this.getAcronym(this.rbkey))
    };
    dialogConfig.width = '800px';

    const dialogRef = this.dialog.open(StateDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined){
        console.log("select state: "+ result);
        this.service.cacheFavoriteState(this.csskey.toString(), result);
        this.selectedState = result;
        this.loadRulebookContents(this.csskey, this.rbkey, result);
      }
    });
  }
}


@Component({
  selector: 'states',
  templateUrl: 'states.html',
  styleUrls: ['../edit-template/edit-template.component.css']
})
export class StateDialog implements OnInit{
  stateChecked: string;
  constructor(
    public dialogRef: MatDialogRef<StateDialog>,
    @Inject(MAT_DIALOG_DATA) public data: {selected: string, states: string[]}
     ) {
    }

    ngOnInit(){}

    onCancel(): void {
      //console.log(this.stateChecked);
      this.dialogRef.close();
    }

    onStateClick(evt$, state: string){
      console.log("click: " + evt$.checked);
      if(evt$.checked)
        this.stateChecked = state;
      else
        this.stateChecked = null;
      this.dialogRef.close(this.stateChecked);
    }
}