<div class="spinner-container" *ngIf="service.loading">
    <mat-spinner></mat-spinner>
</div>
<div class="login login-with-news-feed">
	<div class="news-feed">
        <div class="news-image" style="background-image: url(../../assets/images/random_bg.jpg)"></div>

        <div class="news-caption">
            <a class="brand" href="index.asp">
            	<img src="../../assets/images/stp-logo.png" alt="Audit Checklist Compliance Risk">
            </a>
            <h4 class="caption-title"><b>Specialty Technical Publishers </b></h4>
            <p>PRACTICAL COMPLIANCE & AUDIT SOLUTIONS</p>
        </div>
    </div>

    <div class="right-content">
		<div class="login-header">
			<div class="brand">
                <span><img src="../../assets/images/favicon.ico" alt="" style="float: left;width: 40px;margin-right: 10px;"></span><b>STP Audit Hub</b>
                <br>
                <small style="margin-top: 10px;">For online training, contact us at:</small>
                <small><a href="onlinetraining@stpub.com">onlinetraining@stpub.com</a> or 1-800-251-0381</small>
            </div>
            <div class="icon">
            <i class="fa fa-sign-in"></i>
            </div>
        </div>
        <div class="login-content">
            <form (ngSubmit)="login()" [formGroup]="loginForm" class="stp-form">
                <legend style="margin-bottom: 7px;">
                	<a href="#myModal" role="button" data-toggle="modal" class="btn btn-mini btn-danger pull-right">Help?</a>
                            Login
                </legend>
                <div id="myModal" class="modal fade" tabindex="-1">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="helpdoc">
									<div style="font-size:24px;"><strong>login and Log-out Information</strong></div>
									<h2>Log In</h2>
									<h6>To log in go to <a href="http://stponline.stpub.com/index.asp">http://stponline.stpub.com/index.asp</a></h6>
									<br>
									<p>In the Email form, fill in your corporate e-mail address (i.e. johnsmith@big-company.com). Then fill in your current password.</p>
									<img src="../../assets/images/loginouthelp_fig1.png" alt="Login and logout step 1">
									<p>If you get an error message, or cannot remember your password please click on the “Forgot Password?” button and follow the steps given.</p>
									<img src="../../assets/images/loginouthelp_fig2.png" alt="Login and logout step 2">
									<h2>Log Out</h2>
									<p>To log out go to the top right hand corner and there is a drop list. Click on it and select “Sign Out.”</p>
									<img src="../../assets/images/loginouthelp_fig3.png" alt="Login and logout step 3">
								</div>
							</div>
                            <div class="modal-footer">
                                <button class="btn btn-default" data-dismiss="modal" aria-hidden="true">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="alert-deactivated" class="alert alert-error">
                	<strong>Warning!</strong> It looks like your account is not activated. Please contact your company administrator for assistance.
                </div>
                <div id="alert-disabled" class="alert alert-error">
                            <strong>Warning!</strong> It looks like your account is currently disabled. Please contact your company administrator for assistance.
                </div>
                <div id="alert-password" class="alert alert-error">
                            <strong>Warning!</strong> Your email or password is incorrect.  If you're not sure what your password is try the <i>Forgot My Password</i> button below.
                </div>

                <div id="info-session" class="alert alert-info">
                            <strong>Your session timed out.</strong><br>
                            Please log in again.  If this issue presist please contact your company administrator for assistance.
                </div>

                <div id="alert-access" class="alert alert-error">
                            <strong>You do not have sufficient priviledges to access that page.</strong><br>
                            If this issue presist please contact your company administrator for assistance.
                </div>
                <div class="form-group control-group m-b-15">
                	<input type="text"  class="form-control form-control-lg" formControlName="email" placeholder="Email Address" />
                </div>
                <div class="form-group control-group m-b-15">
                    <input type="password" class="form-control form-control-lg" formControlName="password" placeholder="Password" />
                </div>
                 <p class="alert alert-error"> {{loginErrorMessage}} </p>
                <div class="checkbox checkbox-css m-b-30">
                    <input type="checkbox" id="remember_me_checkbox" value="" />
                    <label for="remember_me_checkbox">Remember Me</label>
                </div>
                <div class="login-buttons control-group">
                    <button [disabled]="loginForm.invalid" class="btn btn-primary btn-block btn-lg">Sign me in</button>
                </div>
            </form>
            <hr />
            <div id="footer">
            	<div class="text center text-grey-darker">
            		<div>
            			<small>
            				<p>
            					&copy; 2020 STP Compliance EHS. All rights reserved.<br />
            					<a href="http://en.stpub.com/terms-conditions">Terms & Conditions</a> |
            					<a href="http://en.stpub.com/documents/pdf/LicenseAgreement.pdf">License Agreement</a> |
            					<a href="http://en.stpub.com/sitemap">Sitemap</a> |
            					<a href="http://en.stpub.com/support">Support</a>
            				</p>
            			</small>
            		</div>
            	</div>
            </div>
        </div>
    </div>
</div>